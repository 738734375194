// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from "../../src/Config";
import { Doughnut } from 'react-chartjs-2';
import './Dashboard.css'; // Import the CSS file
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({ isSidebarExpanded }) => {
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      try {
        // Fetch data for the Doughnut chart
        const chartResponse = await axios.get(`${API_URL}/api/todays_due_preventive_schedules`, {
          headers: {
            Authorization: authKey,
          },
        });

        // Fetch data for the table
        const tableResponse = await axios.get(`${API_URL}/api/todays_department_preventive_schedule_counts`, {
          headers: {
            Authorization: authKey,
          },
        });

        // Set data for the chart
        setChartData(chartResponse.data);

        // Set data for the table
        setTableData(tableResponse.data);

        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  // Format data for the Doughnut chart
  // const pieChartData = {
  //   labels: [
  //     'Active',
  //     'Cancelled',
  //     'Completed',
  //     'Hold',
  //     'In Progress',
  //     'Not in Tenure',
  //     'Overdue',
  //     'Pending'
  //   ], // Labels for the chart
  //   datasets: [
  //     {
  //       data: [
  //         chartData.active || 0,
  //         chartData.cancelled || 0,
  //         chartData.completed || 0,
  //         chartData.hold || 0,
  //         chartData.in_progress || 0,
  //         chartData.not_in_tenure || 0,
  //         chartData.overdue || 0,
  //         chartData.pending || 0,
  //       ], // Data for the chart
  //       backgroundColor: [
  //         '#6E8675',
  //         '#BC7459',
  //         '#BA9358',
  //         '#6E4675',
  //         '#FF6384',
  //         '#36A2EB',
  //         '#FFCE56',
  //         '#FF9F40'
  //       ],
  //       borderColor: '#fff',
  //       borderWidth: 1,
  //     },
  //   ],
  // };
// Format data for the Doughnut chart
const pieChartData = {
  labels: [
    'Active',
    'Cancelled',
    'Completed',
    'Hold',
    'In Progress',
    'Not in Tenure',
    'Overdue',
    'Pending'
  ], // Labels for the chart
  datasets: [
    {
      data: [
        chartData?.active || 0, // Use 0 if the data is null or 0
        chartData?.cancelled || 0,
        chartData?.completed || 0,
        chartData?.hold || 0,
        chartData?.in_progress || 0,
        chartData?.not_in_tenure || 0,
        chartData?.overdue || 0,
        chartData?.pending || 0,
      ], // Data for the chart
      backgroundColor: [
        '#6E8675',
        '#BC7459',
        '#BA9358',
        '#6E4675',
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#FF9F40'
      ],
      borderColor: '#fff',
      borderWidth: 1,
    },
  ],
};

// Render the chart only if there is non-zero data
const hasChartData = pieChartData.datasets[0].data.some(value => value > 0);

  // const doughnutChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: 'bottom',
  //       labels: {
  //         usePointStyle: true,
  //         font: {
  //           size: 12,
  //         },
  //       },
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (tooltipItem) {
  //           return `${tooltipItem.label}: ${tooltipItem.raw}`;
  //         },
  //       },
  //     },
  //   },
  //   cutout: '60%',
  // };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom', // Moves the legend to the bottom
        labels: {
          usePointStyle: true, // Makes legend points circular
          font: {
            size: 14, // Increases font size for better readability
            weight: 'bold', // Makes labels bold
          },
          padding: 20, // Adds padding between legend items
          color: '#333', // Sets color of legend labels
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)', // Dark background for tooltips
        bodyFont: {
          size: 14, // Tooltip text size
        },
        bodyColor: '#fff', // Tooltip text color
        padding: 12, // Adds padding inside tooltips
        borderColor: '#ccc', // Adds a light border to the tooltip
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`; // Custom label for tooltips
          },
        },
      },
    },
    cutout: '60%', // Creates a larger hole in the middle (donut style)
    layout: {
      padding: {
        top: 20,
        bottom: 20, // Adjusts the padding to ensure the chart fits well
      },
    },
    animation: {
      animateScale: true, // Adds a scaling animation to the chart
      animateRotate: true, // Adds rotation animation when chart renders
    },
    borderRadius: 10, // Adds rounded edges to the doughnut segments
    hoverOffset: 10, // Increases the hover effect for better interactivity
  };
  
  return (
    <div className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              Dashboard
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="dashboard-container">
          <div className="dashboard-container-content">
            <div className="chart">
              <div className="dashboard-title">Today's preventive schedules count</div>
              {/* <div className="chart-container">
                <Doughnut data={pieChartData} options={doughnutChartOptions} />
              </div> */}
               <div className="chart-container">
              {hasChartData ? (
                <Doughnut data={pieChartData} options={doughnutChartOptions} />
              ) : (
                <p>No data available for the chart</p> // Display this if no data is available
              )}
            </div>
            </div>
            <div className="dashboard-tables">
              <div className="dashboard-title">Departments & today's preventive schedules count</div>
              <div className="dashbord-table">
                <table>
                  <thead>
                    <tr>
                      <th>SERIAL NO</th>
                      <th>DEPARTMENTS</th>
                      <th>ACTIVE</th>
                      <th>COMPLETED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.department_name || "NA"}</td>
                        <td className='active-status'>{item.active || "0"}</td>
                        <td className='complet-status'>{item.completed || "0"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";


function ProjectView({ isSidebarExpanded }) {
    const [users, setUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUserID, setSelectedUserID] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [isSortPopup, setIsSortPopup] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [showActionHeader, setShowActionHeader] = useState(false);
    const [selectedProjectID, setSelectedProjectID] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);

    const { projectID } = useParams();

    const handleBackView = () => {
        navigate("/project")
    };

    const handleEdit = () => {
        if (selectedProjectID) {
            navigate(`/edit-project/${selectedProjectID}`);
        } else {
            navigate(`/edit-project/${projectID}`);
        }
    };


    const handleActualManpower = () => {
        if (selectedProjectID) {
            navigate(`/actualmanpower/${selectedProjectID}`);
        } else {
            navigate(`/actualmanpower/${projectID}`);
        }
    };

    const handleManpower = () => {
        if (selectedProjectID) {
            navigate(`/man-powers/${selectedProjectID}`);
        } else {
            navigate(`/man-powers/${projectID}`);
        }
    };


    const handleReportManpower = () => {
        if (selectedProjectID) {
            navigate(`/report-manpower/${selectedProjectID}`);
        } else {
            navigate(`/report-manpower/${projectID}`);
        }
    };

    const containerRef = useRef(null);

    useEffect(() => {
        fetchData();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsDeletePopup(false);
            setIsDeletePopupOpen(false);
            setIsSortPopup(false);
            setSelectedRowIndex(null);
        }
    };

    const handleDeletePopup = () => {
        setIsDeletePopup(!isDeletePopup);
        setIsDeletePopupOpen(false);
    };

    const handleDeleteButtonClick = () => {
        setIsDeletePopupOpen(!isDeletePopupOpen);
    };

    const handleCancel = () => {
        setIsDeletePopup(false);
    };

    const fetchData = async () => {
        try {

            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/api/get_projects`, {
                headers: {
                    Authorization: authKey,
                },
            });

            setUsers(response.data.Projects);
            setSelectedUserID(projectID);

        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
        setChecked(newSelectAll);
    };

    const handleSelectRow = (projectID) => {
        const updatedSelectedRows = selectedRows.includes(projectID)
            ? selectedRows.filter((id) => id !== projectID)
            : [...selectedRows, projectID];

        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === users.length);
        setChecked(updatedSelectedRows.length > 0);
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };


    const handleCloseButtonClick = () => {
        setChecked(false);
        setSelectedRows([]);
        setShowActionHeader(false);
    };

    const fetchUserData = async (projectID) => {
        try {

            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(
                `${API_URL}/api/get_project/${projectID}`,
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );
            setUserData(response.data);
            setSelectedUserID(projectID);
            setSelectedProjectID(projectID);
        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };
    
    const togglePopup = async () => {
        try {

            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(
                `${API_URL}/api/get_project/${projectID}`,
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );
            setUserData(response.data);
            setSelectedUserID(projectID);
            setSelectedProjectID(projectID);

        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };
    useEffect(() => {
        togglePopup();
    }, []);
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formatter = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        });
        return formatter.format(date);
    };


    return (
        <>
            <div
                className={`main-container page-content${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div
                    className={`data-lists-content-container page-contents ${isSidebarExpanded ? "expanded" : "collapsed"
                        }`}
                >
                    <div className="data-list-content" ref={containerRef}>
                        {isChecked ? (
                            <div className="action-header">
                                <div className="action-header-content">
                                    <div className="action-header-content-leftside">
                                        <div className="action-button">
                                            <button className="print">
                                                <IoPrintOutline className="print-icon" />
                                            </button>
                                            <button className="bulk-update">Bulk Update</button>
                                            <button
                                                className="delete"
                                                onClick={handleDeleteButtonClick}
                                            >
                                                <HiOutlineDotsHorizontal className="delete-icon" />
                                            </button>
                                            {isDeletePopupOpen && (
                                                <div className="delete-popup">
                                                    <div className="delete-popup-content">
                                                        <button onClick={handleDeletePopup}>
                                                            Move to archive
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {isDeletePopup && (
                                                <div className="confirm-delete-popup">
                                                    <div className="confirm-delete-popup-content">
                                                        <div className="confirm-model">
                                                            <div className="confirm-model-content">
                                                                <div className="confirm-model-content-body">
                                                                    <p>Are you sure want to move archive?</p>
                                                                </div>
                                                                <div className="confirm-model-content-footer">
                                                                    <button
                                                                        className="confirm-delete-button"
                                                                    // onClick={handleConfirmDelete}
                                                                    >
                                                                        Yes
                                                                    </button>
                                                                    <button
                                                                        className="confirm-cancel-button"
                                                                        onClick={handleCancel}
                                                                    >
                                                                        No
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="action-header-content-rightside">
                                        <button
                                            className="action-header-close-button"
                                            onClick={handleCloseButtonClick}
                                        >
                                            <IoCloseOutline className="action-header-close-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="data-list-header">
                                <div className="data-list-header-content">
                                    <div className="data-list-heading-content-left-side">
                                        All Projects
                                    </div>
                                    <div className="data-list-heading-content-right-side">
                                        <Link to="/project-form" className="data-list-new">
                                            <Tooltip title="New User" arrow>
                                                <button className="new-button">
                                                    <HiOutlinePlus className="new-plus-icon" />
                                                </button>
                                            </Tooltip>
                                        </Link>


                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="data-list-body">
                            <div className="data-list-scroll-body">
                                <div className="data-list-table">



                                    <table className="table multilevel-data">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                    />
                                                </th>
                                                <th>NAME</th>
                                            </tr>
                                        </thead>
                                        <tbody className="data-list-scroll-bodys">
                                            {users.map((project, index) => (
                                                <tr key={project.ID} className="hoverable-row">
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRows.includes(project.ID)}
                                                            onChange={() => handleSelectRow(project.ID)}
                                                        />
                                                    </td>

                                                    <td onClick={() => fetchUserData(project.id)} className={selectedUserID === project.id ? 'selected-user' : ''} style={{ cursor: "pointer", color: "blue" }}>
                                                        {project.project_name || "NA"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="data-view-popup">
                    <div className="data-popup-content">
                        <div className="data-view">
                            <div className="data-view-contant">
                                <div className="data-view-header">
                                    <div className="data-view-header-left-side">
                                        {userData && (
                                            <div className="user-header-title">
                                                <div>
                                                    <Tooltip title="Back" arrow>
                                                        <IoMdArrowBack className="back-company" onClick={handleBackView} />
                                                    </Tooltip>
                                                    {userData.project_name} {/* Assuming 'Name' is the correct property */}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className="user-view-header-right-side">
                                        <div className="header-title-edit">
                                            <button className='edit-header-button' onClick={handleManpower}>Man Power requirment</button>
                                            <button className='edit-header-button' onClick={handleActualManpower}>Actual Manpower</button>
                                            <button className='edit-header-button' onClick={handleReportManpower}>Report Man Power</button>
                                            <button className='edit-header-button' onClick={handleEdit}><MdOutlineModeEditOutline className="edit-view-icon" />Edit</button>

                                        </div>
                                    </div>
                                </div>


                                <div className="user-view-body">
                                    <div className="rows">
                                        {userData && (
                                            <div className="user-view-body-container">
                                                <div className="user-view-body-container-content">
                                                    <div className="text-medium-header">Project Details</div>
                                                    <div className="text-medium-body">
                                                        <div className="row">
                                                            <label htmlFor="">Project Name</label>
                                                            <div className="answer">{userData.project_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Customer Name</label>
                                                            <div className="answer">{userData.customer_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">PO Number</label>
                                                            <div className="answer">{userData.po_number || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Project Type</label>
                                                            <div className="answer">{userData.project_type || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Start date</label>
                                                            <div className="answer">{formatDate(userData.start_date || "NA")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">End date</label>
                                                            <div className="answer">{formatDate(userData.end_date || "NA")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Location</label>
                                                            <div className="answer">{userData.customer_location || "NA"}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ transform: "translateY(40px)", marginLeft: "15px" }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ProjectView;

import React, { useState, useEffect } from "react";
import "./UserForm.css";
import "./EquipmentTypeVariantForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";
import AddEquipmentType from "../Popups/AddEquipmentType";
import AddEquipmentSubType from "../Popups/AddEquipmentSubType";
import AddSubDepartmentPopup from "../Popups/AddSubDepartmentPopup";


const { Control, Menu } = components;
function EquipmentTypevariantForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [EquipmentSubTypeMenuIsOpen, setEquipmentSubTypeMenuIsOpen] =
    useState(false);
  const [equipmentSubTypeVariantData, setEquipmentSubTypeVariantData] =
    useState({
      EquipmentSubType: "",
      EquipmentSubTypeId: null,
      VariantName: null,
      Frequencies: [{ OISDPoint: "", Frequency: "" }],
      ApplicableOISD: "",
      Subsection: "",
      DepartmentId: "",
      DepartmentName: "",
      EquipmentType: "",
      EquipmentTypeId: "",
      SubDepartmentId: "",
      SubDepartmentName: "",
    });
  const [showSubTypePopup, setShowSubTypePopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedSubDepartmentId, setSelectedSubDepartmentId] = useState(null);
  const [currentOpenPopup, setCurrentOpenPopup] = useState(null);
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [showequipmentTypePopup, setShowequipmentTypePopup] = useState(false);
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
  const [showSubDepartmentPopup, setShowSubDepartmentPopup] = useState(false);
  const [errors, setErrors] = useState({
    department: "",
    subDepartment: "",
    equipmentType: "",
    equipmentSubType: "",
    variantName: "",
    applicableOISD: "",
    subsection: ""
  });
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentSubTypeVariantData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleCancelClick = () => {
    navigate("/equipment-sub-type-variant");
  };

  const CustomSubDepartmentControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubDepartmentMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Sub Department
      </button>
    </Menu>
  );

  const CustomSubControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Sub Type
      </button>
    </Menu>
  );

  const handleCloseFrequencyRow = (index) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const newFrequencies = prevData.Frequencies.filter((_, i) => i !== index);
      return { ...prevData, Frequencies: newFrequencies };
    });
  };


  const handleNewEquipmentType = (newEquipment) => {
    setEquipmentTypes((prevEquipmentTypes) => [
      ...prevEquipmentTypes,
      newEquipment,
    ]);
    setSelectEdequipmentType(newEquipment);
    setEquipmentSubTypeVariantData((prevEquipmentSubTypes) => ({
      ...prevEquipmentSubTypes,
      EquipmentType: newEquipment.label,
      EquipmentTypeId: newEquipment.value,
    }));
    showSnackbar("Equipment type added successsfully");
  };
  const handleAddNewSubDepartment = () => {
    setShowSubDepartmentPopup(true);
    setSubDepartmentMenuIsOpen(false);
  };
  const closeEquipmentTypePopup = () => {
    setShowequipmentTypePopup(false);

  };
  const CustomTypeControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomTypeMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddEquipmentType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Type
      </button>
    </Menu>
  );

  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };

  const handleAddEquipmentType = () => {
    setShowequipmentTypePopup(true);
    setEquipmentTypeMenuIsOpen(false);
  };

  const handleDepartmentMenuOpen = () => {
    closeAllPopups();
    setDepartmentMenuIsOpen(true);
    setCurrentOpenPopup("department");
  };

  const closeAllPopups = () => {
    setDepartmentMenuIsOpen(false);
    setSubDepartmentMenuIsOpen(false);
    setEquipmentTypeMenuIsOpen(false);
    setEquipmentSubTypeMenuIsOpen(false);
  };

  const handleAddFrequencyRow = () => {
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      Frequencies: [...prevData.Frequencies, { OISDPoint: "", Frequency: "" }],
    }));
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  const closeDepartmentPopup = () => {
    setShowDepartmentPopup(false);
  };

  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setEquipmentSubTypeVariantData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const fetchSubDepartments = async () => {
    try {
      if (selectedDepartment) {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department/${selectedDepartment.value}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const options = data.map((subDepartment) => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } else {
        setSubDepartments([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments();
      setSelectedSubDepartment(null);
    } else {
      setSubDepartments([]);
      setSelectedSubDepartment(null);
    }
  }, [selectedDepartment]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
    setErrors((prevState) => ({
      ...prevState,
      SubDepartmentName: "", // Clear the error for department_name
    }));
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
    setErrors((prevState) => ({
      ...prevState,
      EquipmentType: "", // Clear the error for department_name
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    fetchSubDepartments(selectedOption.value);
    setSelectedSubDepartment(null);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setErrors((prevState) => ({
      ...prevState,
      DepartmentName: "", // Clear the error for department_name
    }));
  };

  const handleEquipmentTypeMenuOpen = () => {
    closeAllPopups();
    setEquipmentTypeMenuIsOpen(true);
    setCurrentOpenPopup("equipmentType");
  };

  const handleSubDepartmentMenuOpen = () => {
    closeAllPopups();
    setSubDepartmentMenuIsOpen(true);
    setCurrentOpenPopup("subDepartment");
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  useEffect(() => {
    if (selectedSubDepartmentId) {
      fetchEquipmentTypes(selectedSubDepartmentId);
    }
  }, [selectedSubDepartmentId]);

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
   

  //   if (error === false) {
  //     const updatedSubTypeVariantData = {
  //       ...equipmentSubTypeVariantData,
  //     };

  //     try {
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");

  //       const response = await axios.post(
  //         `${API_URL}/api/add_sub_type_variant`,
  //         updatedSubTypeVariantData,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );

  //       console.log(response.data.message);
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Sub type variant addded successfully");
  //         setTimeout(() => {
  //           navigate("/equipment-sub-type-variant");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error saving user:", error.message);
  //       // Handle error (e.g., show an error message to the user)
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };
  const handleSaveClick = async () => {
    const updatedErrors = { ...errors };
    let hasError = false;
  
    // Validate Department
    if (!selectedDepartment) {
      updatedErrors.DepartmentName = "Department is required.";
      hasError = true;
    } else {
      updatedErrors.DepartmentName = ""; // Clear error
    }
  
    // Validate Sub Department
    if (!selectedSubDepartment) {
      updatedErrors.SubDepartmentName = "Sub Department is required.";
      hasError = true;
    } else {
      updatedErrors.SubDepartmentName = ""; // Clear error
    }
  
    // Validate Equipment Type
    if (!selectedEquipmentTypes) {
      updatedErrors.EquipmentType = "Equipment Type is required.";
      hasError = true;
    } else {
      updatedErrors.EquipmentType = ""; // Clear error
    }
  
    // Validate Equipment Sub Type
    if (!selectedEquipmentSubType) {
      updatedErrors.EquipmentSubType = "Equipment Sub Type is required.";
      hasError = true;
    } else {
      updatedErrors.EquipmentSubType = ""; // Clear error
    }
  
    // Validate Variant Name
    if (!equipmentSubTypeVariantData.VariantName) {
      updatedErrors.variantName = "Equipment Sub Type Variant is required.";
      hasError = true;
    } else {
      updatedErrors.variantName = ""; // Clear error
    }
  
    // Validate Applicable OISD
    if (!equipmentSubTypeVariantData.ApplicableOISD) {
      updatedErrors.applicableOISD = "Applicable OISD is required.";
      hasError = true;
    } else {
      updatedErrors.applicableOISD = ""; // Clear error
    }
  
    // Validate Subsection
    if (!equipmentSubTypeVariantData.Subsection) {
      updatedErrors.subsection = "Subsection is required.";
      hasError = true;
    } else {
      updatedErrors.subsection = ""; // Clear error
    }
  
    // Update the errors state
    setErrors(updatedErrors);
  
    if (!hasError) {
      const updatedSubTypeVariantData = {
        ...equipmentSubTypeVariantData,
      };
  
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
  
        const response = await axios.post(
          `${API_URL}/api/add_sub_type_variant`,
          updatedSubTypeVariantData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Sub type variant added successfully");
          setTimeout(() => {
            navigate("/equipment-sub-type-variant");
          }, 1000);
        }
      } catch (error) {
        console.error("Error saving user:", error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleEquipmentSubTypeMenuOpen = () => {
    setEquipmentSubTypeMenuIsOpen(true);
  };

  // const CustomControl = ({ children, ...props }) => (
  //   <Control {...props}>{children}</Control>
  // );
  // const CustomMenu = ({ children, ...props }) => (
  //   <Menu {...props}>
  //     {children}
  //     <button
  //       type="button"
  //       className="add-company-button"
  //       onClick={handleAddNewSubType}
  //     >
  //       <CiCirclePlus className="new-plus-circle" />
  //       Add Equipment Sub Type
  //     </button>
  //   </Menu>
  // );
  const handleAddNewSubType = () => {
    setShowSubTypePopup(true);
    setEquipmentSubTypeMenuIsOpen(false);
  };

  // const closeSubTypePopup = () => {
  //   setShowSubTypePopup(false);
  //   // if (newUser) {
  //   //   setCompanies((prevCompanies) => [...prevCompanies, newUser]);
  //   //   setCompany(newUser.label);
  //   //   setSelectedCompany(newUser);
  //   //   setSelectedCompanyId(newUser.value);
  //   // }
  // };

  const closeSubTypePopup = (newSubType) => {
    setShowSubTypePopup(false);
    if (newSubType) {
      setEquipmentSubTypes((prevDepartments) => [
        ...prevDepartments,
        newSubType,
      ]);
      setSelectedEquipmentSubType(newSubType);
      setEquipmentSubTypeVariantData((prevFormData) => ({
        ...prevFormData,
        EquipmentSubType: newSubType.label,
        EquipmentSubTypeId: newSubType.value,
      }));
    }
  };
  const closeSubDepartmentPopup = (newSubDepartment) => {
    setShowSubDepartmentPopup(false);
    fetchSubDepartments();
    if (newSubDepartment) {
      // If a new sub-department is added
      const updatedSubDepartment = {
        label: newSubDepartment.SubDepartment,
        value: newSubDepartment.id,
      };
      setSelectedSubDepartment(updatedSubDepartment);
    }
  };
  const handleNewSubDepartment = (newSubDepartment) => {
    setSubDepartments((prevSubDepartments) => [
      ...prevSubDepartments,
      newSubDepartment,
    ]);
    setSelectedSubDepartment(newSubDepartment);
    setEquipmentSubTypeVariantData((prevSubDepartments) => ({
      ...prevSubDepartments,
      SubDepartmentName: newSubDepartment.label,
      SubDepartmentId: newSubDepartment.value,
    }));

    showSnackbar("Sub Department added successfully")
  };
  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      EquipmentSubType: "", // Clear the error for department_name
    }));
  };

  const handleNewEquipmentSubType = (newSubEquipment) => {
    setEquipmentSubTypes((prevEquipmentSubTypes) => [
      ...prevEquipmentSubTypes,
      newSubEquipment,
    ]);

    const updatedEquipmentSubType = {
      label: newSubEquipment.label,
      value: newSubEquipment.value,
    };

    setSelectedEquipmentSubType(updatedEquipmentSubType);
  };

  const fetchEquipmentSubType = async (equipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_sub_types_by_type/${equipmentTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubType) => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentTypes) {
      fetchEquipmentSubType(selectedEquipmentTypes.value);
    }
  }, [selectedEquipmentTypes]);

  const handleFrequencyChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFrequencies = [...equipmentSubTypeVariantData.Frequencies];
    updatedFrequencies[index][name] = value;
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      Frequencies: updatedFrequencies,
    }));
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipment Sub Type Variant
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                    {errors.DepartmentName && <div className="error-message">{errors.DepartmentName}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="subDepartment"
                    options={subDepartments}
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    components={{
                      Control: CustomSubDepartmentControl,
                      Menu: CustomSubDepartmentMenu,
                    }}
                    placeholder="Select Sub Department"
                    isSearchable
                    menuIsOpen={subDepartmentMenuIsOpen}
                    onMenuOpen={handleSubDepartmentMenuOpen}
                    onMenuClose={() => setSubDepartmentMenuIsOpen(false)}
                  />
                   {errors.SubDepartmentName && <div className="error-message">{errors.SubDepartmentName}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentType">
                    Equipment Type<span className="text-danger">*</span>{" "}
                  </label>

                  <Select
                    id="EquipmentType"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{
                      Control: CustomTypeControl,
                      Menu: CustomTypeMenu,
                    }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                  />
                   {errors.EquipmentType && <div className="error-message">{errors.EquipmentType}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentSubType">
                    Sub Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentSubType"
                    options={equipmentSubTypes}
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    components={{
                      Control: CustomSubControl,
                      Menu: CustomSubMenu,
                    }}
                    placeholder="Select Sub Type"
                    isSearchable
                    menuIsOpen={EquipmentSubTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentSubTypeMenuOpen}
                    onMenuClose={() => setEquipmentSubTypeMenuIsOpen(false)}
                  />
                   {errors.EquipmentSubType && <div className="error-message">{errors.EquipmentSubType}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="VariantName">
                    Equipement Sub Type Variant
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="VariantName"
                      value={equipmentSubTypeVariantData.VariantName}
                      onChange={handleInputChange}
                      placeholder="Enter Sub Type Variant"
                    />
                    
                  </div>
                  {errors.variantName && <div className="error-message">{errors.variantName}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="ApplicableOISD">
                    Applicable OISD<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="ApplicableOISD"
                      value={equipmentSubTypeVariantData.ApplicableOISD}
                      onChange={handleInputChange}
                      placeholder="Enter Applicable OISD"
                    />
                       
                  </div>
                  {errors.applicableOISD && <div className="error-message">{errors.applicableOISD}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="Subsection">
                    Subsection<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Subsection"
                      value={equipmentSubTypeVariantData.Subsection}
                      onChange={handleInputChange}
                      placeholder="Enter subsection"
                    />
                  </div>
                  {errors.subsection && <div className="error-message">{errors.subsection}</div>}
                </div>

              </div>
            </form>

            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
            {showSubDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubDepartmentPopup
                  closePopup={closeSubDepartmentPopup}
                  selectedDepartment={selectedDepartment}
                  parentSelectedDepartment={selectedDepartment}
                  handleNewSubDepartment={handleNewSubDepartment}
                />
              </div>
            )}
            {showequipmentTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentType
                  closePopup={closeEquipmentTypePopup}
                  setSelectEdequipmentType={setSelectEdequipmentType}
                  handleNewEquipmentType={handleNewEquipmentType}
                  parentSelectedDepartment={selectedDepartment}
                  parentSelectedSubDepartment={selectedSubDepartment}
                />
              </div>
            )}
            {showSubTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentSubType
                  closePopup={closeSubTypePopup}
                  setSelectedEquipmentSubType={setSelectedEquipmentSubType}
                  parentSelectedEquipmentType={selectedEquipmentTypes}
                  handleNewEquipmentSubType={handleNewEquipmentSubType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EquipmentTypevariantForm;

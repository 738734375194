import React, { useState } from 'react';
import './Header.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RiNotification3Line } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import API_URL from "../../src/Config";

const Header = () => {
  const navigate = useNavigate();
  const userName = localStorage.getItem('userName');
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const firstLetter = userName ? userName.charAt(0).toUpperCase() : '';


  const handleUsernameLetterClick = () => {
    // Open the popup when clicking on the username letter
    setShowProfilePopup(true);
  };

  const handleLogout = async () => {
    try {
      const authKey = localStorage.getItem('authKey');
  
      // If authKey is present, make the logout request
      if (authKey) {
        await axios.post(`${API_URL}/api/logout`, {}, { headers: { Authorization: authKey } });
      }
  
      // Clear relevant local storage items
      localStorage.removeItem('authKey');
      localStorage.removeItem('userName');
      localStorage.removeItem('currentPage'); 
      localStorage.removeItem('itemsPerPage'); 
      localStorage.removeItem('selectedStatus');   
      // localStorage.removeItem('selectedFilter');
      localStorage.removeItem('company_id');
      localStorage.removeItem('email');
      localStorage.removeItem('menuItems');
      localStorage.removeItem("user_role")
  
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally, show user feedback here
    }
  };
  
  
  


  return (
    <>
      <div className="header">
        <div className="header-layout">
          <div className="header-wrapper">
            <div className="heading-start">
              <img src="/keshav white.png" alt="Logo" className="header-logo" />
            </div>
            <div className="heading-centre">
            </div>
            <div className="heading-end">
              <div className="heading-end-first">

              </div >
              <div className="heading-end-center">
                <div className="notification">
                  <RiNotification3Line className='notification-icon' />
                </div>
                <div className="setting">
                  <IoSettingsOutline className='setting-icon' />
                </div>
              </div>
              <div className="heading-end-last">
                <div className="username"><span>{userName}</span></div>
                <div className="username-letter" onClick={handleUsernameLetterClick}>
                  <div className="letter-background">
                    <div className="first-letter">{firstLetter}</div>
                  </div>
                </div>
                <button className="logout" onClick={handleLogout}>
                  Logout &nbsp;
                <AiOutlineLogout  style={{fontSize: "20px", color:"red"}} />
                </button>
              </div>


              {/* {showProfilePopup && (
                <div className="header-profile-popup">
                  <ProfilePopup closePopup={closeProfilePopup} />
                </div>
              )} */}

              {/* <div className="log-out">
                <button onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_URL from "../../src/Config";
import axios from 'axios';
import "./Login.css";


const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    setUsernameError('');
    setPasswordError('');

    if (!username.trim()) {
      setUsernameError('Enter Your Username.');
      return;
    }

    if (!password.trim()) {
      setPasswordError('Enter Your Password.');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/login`, {
        username,
        password,
      });
      const { message, auth_key, name, email, company_id } = response.data;
      setAlertMessage(message);
      onLogin(auth_key, name, email, company_id);
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000);
    } catch (error) {
      console.error('Full error:', error);
      if (error.response && error.response.status === 401) {
        const message = error.response.data.message;
        console.error('Sign in failed:', message);
        setAlertMessage(message);
      } else {
        console.error('An error occurred during sign in:', error.message);
        setAlertMessage('An error occurred during sign in');
      }
    } finally {
      setLoading(false);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn();
  };


  const handleForgot = () => {
    navigate('/forgotpassword')
  }
  return (

    <div className="login-container">
      <div className="login-content">
        <div className="login-heading">
          <img src="/logo.png" alt="Logo" className="login-logo" />
          <h2>Login</h2>
          <p>Hello! Log in with your email.</p>
        </div>
        <div className="login-alert">
          {alertMessage && (
            <div className={`alert ${alertMessage.includes('Invalid') ? 'alert-danger' : 'alert-success'}`}>
              {alertMessage}
            </div>
          )}
        </div>


        <div className="login-form">
          <form className="form" onSubmit={handleSubmit}>
            <div className="login-form-table">
              <label htmlFor="email">Email address:</label> <br />
              <input
                type="text"
                className={`form-controls ${usernameError ? 'is-invalid' : ''}`}
                placeholder="Enter username or email"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameError('');
                }}
              />
              {usernameError && <div className="invalid-feedbacks">{usernameError}</div>}
            </div>
            <div className="login-form-table">
              <label htmlFor="password">Password:</label> <br />
              <input
                type="password"
                className={`form-controls ${passwordError ? 'is-invalid' : ''}`}
                placeholder="Enter password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
              />
              {passwordError && <div className="invalid-feedbacks">{passwordError}</div>}
            </div>
            <br />
            <div className="login-button">
              <button type="submit" disabled={loading} className="login-btn">
                {loading ? <div className="loads" /> : 'LOG IN'}
              </button>
            </div>
            <div className="login-end">
              <div className="">
                <p className='btn btn-link' onClick={handleForgot} >Forgot Password?</p>

              </div>

              {/* <div className="signup-para">
                <p>
                  Don't have an account? </p><p onClick={handleSignUp} className='btn btn-link'> Sign up</p>


              </div> */}


            </div>
          </form>
        </div>
      </div>
    </div>

  )
}

export default Login;

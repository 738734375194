import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert';
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from '@mui/material/Tooltip';
import API_URL from "../../src/Config";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

function ApprovedTechnical({ isSidebarExpanded, designationID }) {
  const [approvedLists, setApprovedLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();


  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#D4AC0D";
        case "Partially Approved":
        return "#D4AC0D";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
     
      default:
        return "black";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Pending":
        return (
          <MdOutlineWatchLater
            style={{ fontSize: "1.1rem", color: "#D4AC0D", verticalAlign: "top" }}
          />
        );
        case "Partially Approved":
          return (
            <MdOutlineWatchLater
              style={{ fontSize: "1.1rem", color: "#D4AC0D", verticalAlign: "top" }}
            />
          );
      case "Approved":
        return (
          <FaRegCheckCircle style={{ fontSize: "1.1rem", color: "green", verticalAlign: "top" }} />
        );
      case "Rejected":
        return <RxCrossCircled style={{ fontSize: "1.1rem", color: "red", verticalAlign: "top" }} />;
     
      default:
        return null;
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const containerRef = useRef(null);



  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.get(`${API_URL}/api/get_approved_inbound_po_list`, {
        headers: {
          Authorization: authKey,
        },
      });
  
      // Mapping response to get the 'items' array inside 'inbound_purchase_orders'
      const ApprovedRequestLists = response.data.inbound_purchase_orders.items || [];
  
      setApprovedLists(ApprovedRequestLists); // Store the items in the state
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };
  


  const handleSearch = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
        },
      });

      if (response.status === 200) {
        setApprovedLists(response.data);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch();
    }, 600);
    return () => clearTimeout(delay);
  }, [searchInput]);


  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? approvedLists.map((inwardList) => inwardList.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (designationID) => {
    const updatedSelectedRows = selectedRows.includes(designationID)
      ? selectedRows.filter((id) => id !== designationID)
      : [...selectedRows, designationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === approvedLists.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar('Data archived successfully');
        const updatedDesignations = approvedLists.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setApprovedLists(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

 

  const handleArchiveDeparment = () => {
    navigate('/archive-department');

  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const filteredUsers = departments.filter((user) => {
  //   return (
  //     user.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Designation.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.ContactNo.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Department.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  // });

  // const sortedUsers = () => {
  //   if (sortColumn) {
  //     return filteredUsers.slice().sort((a, b) => {
  //       const first = a[sortColumn].toUpperCase();
  //       const second = b[sortColumn].toUpperCase();

  //       if (first < second) {
  //         return sortOrder === "asc" ? -1 : 1;
  //       }
  //       if (first > second) {
  //         return sortOrder === "asc" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return filteredUsers;
  // };
  // const handleSortOrderToggle = () => {
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  // };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleViewClick = async (id) => {
    navigate(`/approved-technical-view/${id}`);
  };
  return (
    <>
      <div className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>Move to archive</button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Product Inventory List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button className="refresh-button" onClick={handleRefresh}>
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by" >SORT BY {isAscending ? '(Ascending)' : '(Descending)'}</div>
                          <div className="sort-by-button">
                      
                        </div>
                          {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Department</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : approvedLists.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>MAT CODE</th>
                          <th>TOTAL QUANTITY</th>
                          <th>TECHNICAL INSPECTION PENDING</th>
                          <th>AVAILABLE QUANTITY</th>
                       
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                      {approvedLists.map((approvedList, index) => (
                          <tr key={approvedList.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(approvedList.id)}
                                onChange={() => handleSelectRow(approvedList.id)}
                              />
                            </td>
        
                            <td>{index + 1}</td>
                            <td>{approvedList.mat_code || "NA"}</td>
                            <td>{approvedList.invoice_no || "NA"}</td>
                            <td>{approvedList.vendor_name || "NA"}</td>
                            {/* <td>{approvedList.status || "NA"}</td> */}
                            <td
                              style={{
                                color: getStatusColor(approvedList.status),
                              }}
                            >
                              {getStatusIcon(approvedList.status)}{" "}
                              {approvedList.status || "NA"}
                            </td>
                           
                            <td>
                              <button
                                onClick={() => handleViewClick(approvedList.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                           
                         
                          </tr>

                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Product Inventory List Found</div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: '35px', marginLeft: '20px' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ApprovedTechnical;

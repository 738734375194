import React, { useState, useEffect } from "react";
import "./UserForm.css";
import "./EquipmentTypeForm.css";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { CiCirclePlus } from "react-icons/ci";
import AddDepartmentPopup from "../Popups/AddDepartmentPopup";

const { Control, Menu } = components;
function SubDepartmentForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [severity, setSeverity] = useState("success");

  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [subDepartmentData, setSubDepartmentData] = useState({
    DepartmentName: "",
    DepartmentId: "",
    SubDepartment: "",
  });
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleCancelClick = () => {
    navigate("/sub-department");
  };

  const handleSaveClick = async () => {

    const errors = {};
    let error = false;
    Object.keys(subDepartmentData).forEach( (key) => {
      if (!subDepartmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning")
        showSnackbar("Required fields are empty");
        error = true;
      } 
    });

    if(error === false) {
    const updatedSubDepartmentData = {
      ...subDepartmentData,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_sub_department`,
        updatedSubDepartmentData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success")
        showSnackbar("Sub Department addded successfully");
        setTimeout(() => {
          navigate("/sub-department");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving user:", error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );
  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };
  const handleDepartmentChange = (selectedOption) => {
    setDepartments(selectedOption.label);
    setSelectedDepartment(selectedOption);
    setSubDepartmentData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
  };

  // const fetchDepartment = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const apiUrl = `${API_URL}/api/get_departments`;

  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: authKey,
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       // Map the API response to the format expected by react-select
  //       const options = data.map((department) => ({
  //         label: department.DepartmentName,
  //         value: department.ID,
  //       }));

  //       setDepartments(options);
  //     } else {
  //       console.error("Failed to fetch departments");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching departments:", error);
  //   }
  // };


  // useEffect(() => {
  //   fetchDepartment();
  // }, []);

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);


  const closeDepartmentPopup = (newDepartment) => {
    setShowDepartmentPopup(false);
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setSubDepartmentData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
    }
  };
  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setSubDepartmentData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Sub-Department
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="DepartmentName">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                   id="department"
                   options={departments}
                   value={selectedDepartment}
                   onChange={handleDepartmentChange}
                  //  onInputChange={handleInputChange}
                   components={{ Control: CustomControl, Menu: CustomMenu }}
                   placeholder="Select Department"
                   isSearchable
                  //  onKeyDown={handleKeyDown}
                   menuIsOpen={departmentMenuIsOpen}
                   onMenuOpen={handleDepartmentMenuOpen}
                   onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />

                </div>
                <div className="form-group">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="SubDepartment"
                      value={subDepartmentData.SubDepartment}
                      onChange={handleInputChange}
                      placeholder="Enter Sub Department"
                    />
                  </div>
                </div>
              </div>
            </form>
            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default SubDepartmentForm;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";

function UpdateMatCode({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [levelOneOptions, setLevelOneOptions] = useState([]);
  const [levelTwoOptions, setLevelTwoOptions] = useState([]);
  const [levelThreeOptions, setLevelThreeOptions] = useState([]);


  const [formData, setFormData] = useState({
    levelOneId: "",
    levelTwoId: "",
    levelThreeId: "",
    levelFour: "",
    levelFive: "",
    Description: "",
    MatCode: "",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMatCodeData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const url = `${API_URL}/api/get_mat_code_details/${id}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: authKey,
          },
        });

        const responseData = response.data;

        if (responseData) {
          setFormData({
            levelOneId: responseData.level_one_id,
            level_one: responseData.level_one,
            level_three: responseData.level_three,
            level_two: responseData.level_two,
            levelTwoId: responseData.level_two_id,
            levelThreeId: responseData.level_three_id,
            levelFour: responseData.level_four,
            levelFive: responseData.level_five,
            Description: responseData.description,
            MatCode: responseData.mat_code,
          });

          // Fetch level one options
          fetchLevelOneData(
            responseData.level_one_id,
            responseData.level_two_id,
            responseData.level_three_id
          );
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error("Error fetching material code data:", error.message);
        setError("An error occurred while fetching the data");
      }
    };

    fetchMatCodeData();
  }, [id]);

  const fetchLevelOneData = async (
    selectedLevelOneId,
    selectedLevelTwoId,
    selectedLevelThreeId
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_ones`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const options = response.data.map((level) => ({
        label: `${level.level} (${level.description})`,
        value: level.id,
      }));

      setLevelOneOptions(options);

      // Set selected Level One ID
      if (selectedLevelOneId) {
        setFormData((prev) => ({ ...prev, levelOneId: selectedLevelOneId }));
        fetchLevelTwoData(
          selectedLevelOneId,
          selectedLevelTwoId,
          selectedLevelThreeId
        );
      }
    } catch (error) {
      console.error("Error fetching Level 1 data:", error.message);
    }
  };

  // Fetch Level Two Data
  const fetchLevelTwoData = async (
    levelOneId,
    selectedLevelTwoId,
    selectedLevelThreeId
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_two_by_level_one/${levelOneId}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const options = response.data.map((item) => ({
        label: `${item.level} (${item.description})`,
        value: item.id,
      }));

      setLevelTwoOptions(options);

      // Set selected Level Two ID
      if (selectedLevelTwoId) {
        setFormData((prev) => ({ ...prev, levelTwoId: selectedLevelTwoId }));
        fetchLevelThreeData(selectedLevelTwoId, selectedLevelThreeId);
      }
    } catch (error) {
      console.error("Error fetching Level 2 data:", error.message);
    }
  };

  // Fetch Level Three Data
  const fetchLevelThreeData = async (levelTwoId, selectedLevelThreeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_mat_code_level_three_by_level_two/${levelTwoId}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const options = response.data.map((item) => ({
        label: `${item.level} (${item.description})`,
        value: item.id,
      }));

      setLevelThreeOptions(options);

      // Set selected Level Three ID
      if (selectedLevelThreeId) {
        setFormData((prev) => ({
          ...prev,
          levelThreeId: selectedLevelThreeId,
        }));
      }
    } catch (error) {
      console.error("Error fetching Level 3 data:", error.message);
    }
  };
  const generateMatCode = (
    levelOneId,
    levelTwoId,
    levelThreeId,
    levelFour,
    levelFive
  ) => {
    // Find the description for Level One
    const levelOneDesc =
      levelOneOptions
        .find((option) => option.value === levelOneId)
        ?.label.split(" ")[0] || "xx"; // Extract description part
    const levelTwoDesc =
      levelTwoOptions
        .find((option) => option.value === levelTwoId)
        ?.label.split(" ")[0] || "xx"; // Same for level Two
    const levelThreeDesc =
      levelThreeOptions
        .find((option) => option.value === levelThreeId)
        ?.label.split(" ")[0] || "xx"; // Same for level Three

    // Generate the MatCode
    const code = `${levelOneDesc}-${levelTwoDesc}-${levelThreeDesc}-${
      levelFour || "xx"
    }-${levelFive || "xx"}`;

    // Update the form data with the generated code
    setFormData((prevState) => ({
      ...prevState,
      MatCode: code,
    }));
  };

  // Handle Level One Change
  const handleLevelOneChange = (selectedOption) => {
    const levelOneId = selectedOption ? selectedOption.value : "";
    setFormData((prev) => ({
      ...prev,
      levelOneId,
      levelTwoId: "",
      levelThreeId: "",
    }));
    fetchLevelTwoData(levelOneId, "", "");
    generateMatCode(levelOneId, formData.levelTwoId, formData.levelThreeId);
  };
 
  const handleLevelTwoChange = (selectedOption) => {
    const levelTwoId = selectedOption ? selectedOption.value : "";
    setFormData((prev) => ({
      ...prev,
      levelTwoId,
      levelThreeId: "",
    }));
    fetchLevelThreeData(levelTwoId, "");
    generateMatCode(formData.levelOneId, levelTwoId, ""); // Update MatCode after Level Two change
  };
  // Handle Level Three Change
  const handleLevelThreeChange = (selectedOption) => {
    const levelThreeId = selectedOption ? selectedOption.value : "";
    setFormData((prev) => ({ ...prev, levelThreeId }));
    generateMatCode(formData.levelOneId, formData.levelTwoId, levelThreeId);
  };
  const handleLevelFiveChange = (event) => {
    const levelFive = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      levelFive,
    }));
    generateMatCode(
      formData.levelOneId,
      formData.levelTwoId,
      formData.levelThreeId,
      formData.levelFour,
      levelFive
    );
  };

  const handleLevelFourChange = (event) => {
    const levelFour = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      levelFour,
    }));
    generateMatCode(
      formData.levelOneId,
      formData.levelTwoId,
      formData.levelThreeId,
      levelFour,
      formData.levelFive
    );
  };

  // Handle Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Snackbar Close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Show Snackbar
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Handle Save Click
  const handleSaveClick = async () => {
    const errors = {};
    let error = false;

    // Validate the input fields
    if (!formData.levelOneId) {
      errors.levelOneId = "Level One ID is required";
      error = true;
    }
    if (!formData.levelTwoId) {
      errors.levelTwoId = "Level Two ID is required";
      error = true;
    }
    if (!formData.levelThreeId) {
      errors.levelThreeId = "Level Three ID is required";
      error = true;
    }
    if (!formData.levelFour) {
      errors.levelFour = "Level Four is required";
      error = true;
    }
    if (!formData.levelFive) {
      errors.levelFive = "Level Five is required";
      error = true;
    }
    if (!formData.MatCode) {
      errors.MatCode = "Material Code is required";
      error = true;
    }

    if (error) {
      console.log(errors);
      return; // Stop execution if there are validation errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Sending the request
      const response = await axios.put(
        `${API_URL}/api/update_mat_code/${id}`,
        {
          LevelOneId: formData.levelOneId,
          LevelTwoId: formData.levelTwoId,
          LevelThreeId: formData.levelThreeId,
          LevelFour: formData.levelFour,
          LevelFive: formData.levelFive,
          Description: formData.Description,
          MatCode: formData.MatCode,
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Material Code updated successfully!");
        navigate("/mat-code"); // Redirect after success
      }
    } catch (error) {
      console.error("Error updating material code:", error);
      showSnackbar("Failed to update Material Code.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = () => {
    navigate("/mat-code");
  };

  const handleBackProject = () => {
    navigate("/mat-code");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Mat Code
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail">
              {error && <p className="text-danger">{error}</p>}
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="LevelOne">
                    Level 1<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelOneOptions}
                    onChange={handleLevelOneChange}
                    value={
                      levelOneOptions.find(
                        (option) => option.value === formData.levelOneId
                      ) || null
                    }
                    placeholder="Select Level One"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="LevelTwo">
                    Level 2<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelTwoOptions}
                    onChange={handleLevelTwoChange}
                    value={
                      levelTwoOptions.find(
                        (option) => option.value === formData.levelTwoId
                      ) || null
                    }
                    placeholder="Select Level Two"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="LevelThree">
                    Level 3<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelThreeOptions}
                    onChange={handleLevelThreeChange}
                    value={
                      levelThreeOptions.find(
                        (option) => option.value === formData.levelThreeId
                      ) || null
                    }
                    placeholder="Select Level Three"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Level 4<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="levelFour"
                      value={formData.levelFour}
                      // onChange={handleInputChange}
                      onChange={handleLevelFourChange}
                      placeholder="Enter Level 4 (2 digit)"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Level 5<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="levelFive"
                      value={formData.levelFive}
                      // onChange={handleInputChange}
                      onChange={handleLevelFiveChange}
                      placeholder="Enter level 5 (2 digit)"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Description"
                      value={formData.Description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                </div>

                <div className="mate-code-button">
                  <input
                    type="text"
                    id="MatCode"
                    value={formData.MatCode || ""}
                    placeholder="xx-xx-xx-xx-xx"
                    readOnly
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UpdateMatCode;
import React, { useState, useEffect, useRef } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import "./PreventiveReportView.css";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import Pagination from "../Component/Pagination";

function ProcurementreportView({ isSidebarExpanded }) {
  const { department_id, pr_status, filter_option } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  // const fetchData = async (currentPage, itemsPerPage) => {
  //   try {
  //     setLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     const response = await axios.get(`${API_URL}/api/get_pr_report_details`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //       params: {
  //         department_id,
  //         filter_option: filter_option,
  //         pr_status,
  //         page: currentPage,
  //         per_page: itemsPerPage,

  //       },
  //     });
  
  //     setReports(response.data.schedules);
  //     setTotalItems(response.data.total);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching schedule report:", error.message);
  //     setLoading(false);
  //   }
  // };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_pr_report_details`, {
        headers: { Authorization: authKey },
        params: {
          department_id,
          filter_option,
          pr_status,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
  
      console.log("API Response:", response.data);
  
      const prRequests = response.data.pr_requests || [];
      setReports(prRequests);
      setTotalItems(response.data.total || 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching procurement reports:", error.message);
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    const storedItemsPerPage = localStorage.getItem("itemsPerPage");
  
    const page = storedPage ? parseInt(storedPage, 10) : 0;
    const itemsPerPage = storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 50;
    setPage(page);
    setItemsPerPage(itemsPerPage);
  
    fetchData(page, itemsPerPage);
  }, [department_id, filter_option, pr_status, page, itemsPerPage]);
  
  
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };
  

  // useEffect(() => {
  //   fetchData();
  // }, [department_id, filter_option, pr_status]);

  const containerRef = useRef(null);

  
  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? reports.map((schedule) => schedule.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === reports.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleArchiveschedule = async () => { };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleRefresh = () => {
    fetchData();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  const handleBackScheduler = () => {
    navigate("/reports?tab=procurement-reports");
  };
  
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return '#2980B9';
      case 'cancelled':
        return '#F81700';
      case 'Hold':
        return '#D4AC0D';
      case 'not in tenure':
        return '#313639';
      case 'overdue':
        return '#C0612B';
      case 'In Progress':
        return '#df7f26';
      case 'Completed':
        return '#2E8B57';
      default:
        return 'black'; // Fallback color if needed
    }
  };
  const filterOptionMap = {
    all: 'All',
    today: 'Today',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    next7days: 'Next 7 Days',
    next_month: 'Next Month',
    current_week: 'Current Week',
    previous_month: 'Previous Month',
    past_week: 'Previous Week',
    current_month: 'Current Month',
    custom_date: 'Custom Date',

    // Add other mappings as needed
  };
  // Get the display text based on filter_option
  const displayText = filterOptionMap[filter_option] || filter_option;

  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveschedule}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackScheduler}
                      />
                    </Tooltip>
                    {reports.length > 0 && (
                      <>
                       <span className="report-name">Procurement Report</span>
                        <span className="status-show" style={{ color: getStatusColor(reports[0].status), fontWeight: '500' }}>
                          {reports[0].status ? reports[0].status.charAt(0).toUpperCase() + reports[0].status.slice(1).toLowerCase() : "0"}
                        </span>
                        <span className="department-show" style={{ color: 'green', marginLeft: '15px', fontWeight: '500' }}>
                          {reports[0].department_name ? reports[0].department_name.charAt(0).toUpperCase() + reports[0].department_name.slice(1).toLowerCase() : "0"}
                        </span>
                        <span className="option-show" style={{ color: '#ce9139ed', marginLeft: '15px', fontWeight: '500' }}>
                        {displayText}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="data-list-heading-content-right-side">
                    {/* <div className="dropdown-container">
                      <Select
                        options={statusOptions}
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        placeholder="Select Status"
                        className="project-dropdown"
                      />
                    </div> */}
                    {/* <div className="dropdown-container">
                      <Select
                        value={selectedFilter}
                        onChange={handleFilterChange}
                        options={filterOptions}
                        className="project-dropdown"
                        
                      />
                    </div> */}
                    {/* {selectedFilter === "custom_date" && (
                      <div className="input-group-datepickers">
                        <DatePicker
                          placeholderText="Select Date Range"
                          className="dates"
                          startDate={selectedStartDate}
                          endDate={selectedEndDate}
                          selectsRange={true}
                          dateFormat="dd/MM/yyyy"
                          isClearable
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          onChange={(dates) => {
                            const [start, end] = dates;
                            setSelectedStartDate(start);
                            setSelectedEndDate(end);
                          }}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField {...startProps} variant="standard" />
                              <TextField {...endProps} variant="standard" />
                            </>
                          )}
                          startText="Start Date"
                          endText="End Date"
                        />
                      </div>
                    )} */}

                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-bodys">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) :   reports && Array.isArray(reports) && reports.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          {/* <th>PR REQUEST ID</th> */}
                          <th>DEPARTMENT</th>
                          <th>PR TYPE</th>
                          <th>QUANTITY</th>
                          <th>STATUS</th>
                          <th>ESTIMATED UNIT RATE</th>
                          <th>ESTIMATED COST</th>
                          <th>PREFERRED VENDOR</th>
                          {/* <th>DELIVERY DATE</th> */}


                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {reports.map((report, index) => (
                          <tr key={index} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(report.id)}
                                onChange={() => handleSelectRow(report.id)}
                              />
                            </td>

                            <td>{index + 1}</td>

                            <td>{report.department_name || "NA"}</td>
                            <td>{report.pr_type || "NA"}</td>
                            <td>{report.qty || "NA"}</td>
                            <td>{report.pr_status || "NA"}</td>
                            <td>{report.est_unit_rate || "NA"}</td>
                            <td>{report.est_cost || "NA"}</td>
                            <td>{report.preferred_vendor || "NA"}</td>
                            {/* <td>
                              {formatDate(report.generated_at || "NA")}
                            </td> */}



                            {/* <td>
                              <button
                                onClick={() => handleViewClick(report.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Procurement Reports Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ProcurementreportView;

import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import API_URL from "../../src/Config";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function UserForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const company_id = localStorage.getItem("company_id");
  const [userData, setUserData] = useState({
    Name: "",
    Designation: "",
    Email: "",
    ContactNo: "",
    Password: "",
    ConfirmPassword: "",
    role: "",
    department_id: "",
    department_name: "",
  });


  const [firstNameError, setFirstNameError] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedProject, setSelectedProject] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [contactNoError, setContactNoError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [userTypeError, setUserTypeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectedSalutation, setSelectedSalutation] = useState("Mr.");
  const [projects, setProjects] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [userType, setUserType] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [designationMenuIsOpen, setDesignationMenuIsOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // New state for password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const options = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Miss", label: "Miss" },
  ];

  const fetchUserTypes = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_user_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        const data = response.data.map((userType) => ({
          value: userType.id,
          label: userType.type_name,
        }));
        setUserType(data);
      } else {
        console.error("Failed to fetch user type data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user type data:", error.message);
    }
  };
  useEffect(() => {
    fetchUserTypes();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleChange = (selectedOption) => {
    // setNameTitle(selectedOption.value);
    setSelectedSalutation(selectedOption.value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "FirstName" || name === "LastName" || name === "Title") {
    }

    if (name === "FirstName") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        Name: `${selectedSalutation} ${value} ${prevUserData.LastName}`.trim(),
        FirstName: value,
      }));
    } else if (name === "LastName") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        Name: `${selectedSalutation} ${prevUserData.FirstName} ${value}`.trim(),
        LastName: value,
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
    switch (name) {
      case "FirstName":
        setFirstNameError("");
        break;
      case "UserType":
        setUserTypeError("");
        break;
      case "Email":
        setEmailError("");
        break;
      case "ContactNo":
        setContactNoError("");
        break;
      case "Designation":
        setDesignationError("");
        break;
      case "Password":
        setPasswordError("");
        break;
        case "ConfirmPassword":
        setConfirmPasswordError("");
        break;
      case "department_name":
        setDepartmentError("");
        break;
      default:
        break;
    }
  };
  const fetchDesignationData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_designations`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        const data = response.data.map((designation) => ({
          value: designation.id,
          label: designation.designation_name,
        }));
        setDesignations(data);
      } else {
        console.error("Failed to fetch designation data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching designation data:", error.message);
    }
  };
  useEffect(() => {
    fetchDesignationData();
  }, []);

  const handleCancelClick = () => {
    navigate("/user");
  };



  const handleSaveClick = async () => {
    let isValid = true;

    if (!userData.FirstName) {
      setFirstNameError("First Name is required");
      isValid = false;
    }

    if (!userData.Email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(userData.Email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }
    if (!userData.ContactNo) {
      setContactNoError("Contact No is required");
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(userData.ContactNo)) {
      setContactNoError("Please enter 10 digit number");
      isValid = false;
    }

    if (!userData.Designation) {
      setDesignationError("Designation is required");
      isValid = false;
    }
    if (!userData.role) {
      setUserTypeError("User Type is required");
      isValid = false;
    }
    if (!userData.department_name) {
      setDepartmentError("Department is required");
      isValid = false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!userData.Password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (!passwordRegex.test(userData.Password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character"
      );
      isValid = false;
    }

    // if (userData.Password !== userData.ConfirmPassword) {
    //   setConfirmPasswordError("Passwords do not match");
    //   isValid = false;
    // } else {
    //   setConfirmPasswordError("");
    // }
    if (!userData.ConfirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      isValid = false;
    } else if (userData.Password !== userData.ConfirmPassword) {
      setConfirmPasswordError(
        "Passwords do not match"
      );
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    const updatedUserData = {
      ...userData,
      company_id: company_id,
      project_ids: selectedProject.map((project) => project.value),
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_user`,
        updatedUserData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("User added successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/user");
        }, 1000);
      } else {
        console.error("Failed to add new user");
        setSnackbarMessage("Failed to add new user");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "Email already registered"
      ) {
        setSnackbarMessage("Email already registered");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      } else {
        console.error("Error adding new user:", error.message);
        setSnackbarMessage("An error occurred while adding new user");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_projects`, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log(response.data);

      const projectOptions = response.data.map((project) => ({
        value: project.id,
        label: project.project_name,
      }));

      setProjects(projectOptions);
    } catch (error) {
      console.error("Error fetching project data:", error.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };
  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const handleDesignationMenuOpen = () => {
    setDesignationMenuIsOpen(true);
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setUserData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setDepartmentError("");
  };

  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
    setUserData((prevUserData) => ({
      ...prevUserData,
      Designation: selectedOption ? selectedOption.label : "",
    }));
    setDesignationError("");
  };

  const handleUserTypeChange = (selectedOption) => {
    setSelectedUserType(selectedOption);
    setUserData((prevUserData) => ({
      ...prevUserData,
      role: selectedOption ? selectedOption.label : "",
    }));
    setUserTypeError("");
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New User Registration
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-selectname">
                  <label htmlFor="Name">
                    Name<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="Name"
                    value={options.find(
                      (option) => option.value === selectedSalutation
                    )}
                    options={options}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="FirstName"
                    value={userData.FirstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                  />
                 
                  <input
                    type="text"
                    name="LastName"
                    value={userData.LastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                  />
                   {firstNameError && (
                    <span className="texts-danger">{firstNameError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />

                  {departmentError && (
                    <span className="error-message">{departmentError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="Designation">
                    Designation<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="designation"
                    options={designations}
                    value={selectedDesignation}
                    onChange={handleDesignationChange}
                    placeholder="Select Designation"
                    isSearchable
                    menuIsOpen={designationMenuIsOpen}
                    onMenuOpen={handleDesignationMenuOpen}
                    onMenuClose={() => setDesignationMenuIsOpen(false)}
                  />
                  {designationError && (
                    <span className="error-message">{designationError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="Email">
                    Email (Username)<span className="text-danger">*</span>{" "}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Email"
                      value={userData.Email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                  {emailError && (
                    <span className="error-message">{emailError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="ContactNo">
                    Contact No<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="ContactNo"
                      value={userData.ContactNo}
                      onChange={handleInputChange}
                      placeholder="Enter contact number"
                    />
                  </div>
                  {contactNoError && (
                    <span className="error-message">{contactNoError}</span>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="UserType">
                    User Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    options={userType}
                    value={selectedUserType}
                    onChange={handleUserTypeChange}
                    placeholder="Select user type"
                    isSearchable
                  />
                  {userTypeError && (
                    <span className="error-message">{userTypeError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="Projects">
                    Assign Project
                  </label>
                  <Select
                    id="projects"
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    placeholder="Select project"
                    isSearchable
                    isMulti
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="Password">
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="password"
                      name="Password"
                      value={userData.Password}
                      onChange={handleInputChange}
                      placeholder="Enter password"
                    />
                  </div>
                  {passwordError && (
                    <span className="error-message">{passwordError}</span>
                  )}
                </div> */}
                <div className="form-group">
                  <label htmlFor="Password">
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group password-group">
                    <>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="Password"
                      placeholder="Password"
                      value={userData.Password}
                      onChange={handleInputChange}
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="password-toggle-button"
                    >
                      {passwordVisible ? <FaEyeSlash className="pasword-icon" /> : <FaEye className="pasword-icon" />}
                    </button>
                    </>
                  </div>
                  {passwordError && (
                    <span className="error-message">{passwordError}</span>
                  )}
                </div>
             
                <div className="form-group ">
                  <label htmlFor="ConfirmPassword">
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group password-group">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      name="ConfirmPassword"
                      value={userData.ConfirmPassword}
                      onChange={handleInputChange}
                      placeholder="Enter Confirm Password"
                    />
                     <button
                      type="button"
                      onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                      className="password-toggle-button"
                    >
                      {confirmPasswordVisible ? <FaEyeSlash className="pasword-icon" /> : <FaEye className="pasword-icon" />}
                    </button>
                  </div>
                  {confirmPasswordError && (
                    <span className="error-message">
                      {confirmPasswordError}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UserForm;

import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import "./TechnicalRequestListView.css";
import { useNavigate, useParams } from "react-router-dom"; // useParams to get po_id
import axios from "axios"; // Ensure axios is imported
import API_URL from "../../src/Config"; // Ensure the correct path to your config file
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function TechnicalRequestListView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams(); // Get po_id from the route parameters
  const [selectedVendor, setSelectedVendor] = useState(null);
  //   const [poData, setPoData] = useState({ po_no: "" });
  const [technicalLists, setTechnicalLists] = useState([]);
  const [rows, setRows] = useState([]);
  const [technicalListData, setTechnicalListData] = useState({
    approvedqty: "",
  });

  const [inboundId, setInboundId] = useState(null); // New state to store inbound_id

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_inbound_purchase_orders/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const poDataResponse = response.data.inbound_purchase_orders[0]; // Accessing the first PO from the list

      // Set data into the respective states
      setTechnicalLists({ po_no: poDataResponse.po_no }); // Accessing PO number from the response
      setSelectedVendor({
        label: poDataResponse.vendor_name,
        value: poDataResponse.vendor_id,
      });

      // Set inboundId from response
      setInboundId(poDataResponse.id); // Set the inbound_id

      // Map items to rows for the table
      const rowsData = poDataResponse.items.map((item) => ({
        matCodes: item.mat_code, // Mat Code ID
        quantity: item.qty, // Quantity
        price: item.price,
        est_unit_rate: item.est_unit_rate, // Price
        description: item.description, // Description
        inbounded_qty: item.inbounded_qty,
        inbound_list_id: item.id,
        IsApproved: item.IsApproved,
      }));

      setRows(rowsData);

      // Handle attachments if needed
      const attachments = poDataResponse.attachments.map((attachment) => ({
        file_id: attachment.file_id,
        file_name: attachment.filename,
        file_path: attachment.file_path,
        file_type: attachment.file_type,
        uploaded_at: attachment.uploaded_at,
      }));

      // If you're managing attachments separately
      // setAttachments(attachments);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]); // Only fetch when po_id is available

  const handleBackProject = () => {
    navigate("/technical-pr-request-list");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveQty = async (itemId, status) => {
  //   setIsLoading(true); // Start loading state

  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     // Find the specific item based on itemId
  //     const item = rows.find((row) => row.inbound_list_id === itemId);

  //     if (!item) {
  //       throw new Error("Item not found");
  //     }

  //     // Prepare the payload object for a single item
  //     const payload = {
  //       inbound_id: inboundId, // Add the inbound_id
  //       inbound_list_id: item.inbound_list_id, // Include the specific item ID
  //       approved_qty:
  //         technicalListData.approvedqty[item.id] || item.inbounded_qty, // Get the approved quantity
  //       status: status, // Set status based on the button clicked
  //     };

  //     // Send the PUT request
  //     const response = await axios.put(
  //       `${API_URL}/api/update_inbound_po_status`,
  //       payload, // Send the payload as JSON
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json", // Set content type to application/json
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Purchase order updated successfully");
  //       setTimeout(() => {
  //         navigate("/technical-pr-request-list");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving inbound purchase orders:", error);
  //     setSeverity("error");
  //     showSnackbar("Failed to update purchase order");
  //   } finally {
  //     setIsLoading(false); // End loading state
  //   }
  // };

  const handleSaveQty = async (itemId, status) => {
    setIsLoading(true); // Start loading state

    try {
      const authKey = localStorage.getItem("authKey");
      const item = rows.find((row) => row.inbound_list_id === itemId);

      if (!item) {
        throw new Error("Item not found");
      }

      // Prepare the payload object for a single item
      const payload = {
        inbound_id: inboundId, // Add the inbound_id
        inbound_list_id: item.inbound_list_id, // Include the specific item ID
        rejected_qty:item.inbound_list_id,
        status: status, // Set status based on the button clicked
      };


      const response = await axios.put(
        `${API_URL}/api/update_inbound_po_status`,
        payload, // Send the payload as JSON
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json", // Set content type to application/json
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Purchase order updated successfully");
        setTimeout(() => {
          navigate("/technical-pr-request-list");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving inbound purchase orders:", error);
      setSeverity("error");
      showSnackbar("Failed to update purchase order");
    } finally {
      setIsLoading(false); // End loading state
    }
  };
  const handleRejectedQtyChange = (index, value) => {
    const numericValue = value === "" ? 0 : Number(value); // Convert to number, default to 0 if cleared
    // Update state or perform actions with numericValue as needed
    console.log(`Rejected quantity for index ${index}:`, numericValue);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Technical Inspection Items
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="project-detailss">
                <div className="project-detail-arround">
                  <div className="project-details-left-side">
                    <div className="project-detail">
                      <div className="detail-header">PO Number:</div>
                      <div className="detail-part">
                        {technicalLists.po_no || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Invoice No:</div>
                      <div className="detail-part">
                        {technicalLists.po_no || "N/A"}
                      </div>
                    </div>
                    <div className="project-detail">
                      <div className="detail-header">Vendor:</div>
                      {/* Display Vendor name here */}
                      <div className="detail-part">
                        {selectedVendor ? selectedVendor.label : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Item Table
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <table className="action-table action-multilevel-data ">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="th2">MAT CODE</th>
                        <th className="th3">QTY</th>
                        <th className="th3">ESTIMATED UNIT RATE</th>
                        <th className="th5">DESCRIPTION</th>
                        <th className="th4">PRICE</th>
                        <th className="th4">INWARD QTY</th>
                        <th className="th4">ACCEPTED QTY</th>
                        <th className="th4">REJECTED QTY</th>
                        <th className="th4">ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="action-purchase-scroll-bodys">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="th1">{row.matCodes || "N/A"}</td>
                          <td className="th2">{row.quantity || "N/A"}</td>
                          <td className="th3">{row.est_unit_rate || "N/A"}</td>
                          <td className="th5">{row.description || "N/A"}</td>
                          <td className="th4">{row.price || "N/A"}</td>
                          <td className="th4">{row.inbounded_qty || "N/A"}</td>
                          <td className="th4">{row.inbounded_qty}</td>
                          <td className="th3">
                            <input
                              type="number"
                              defaultValue={0} // Set default value to 0
                              onChange={(e) =>
                                handleRejectedQtyChange(
                                  index, // Using index here
                                  e.target.value
                                )
                              }
                              placeholder="Enter rejected"
                            />
                          </td>

                          <td className="th4">
                            <button
                              className="data-form-save-button"
                              onClick={() =>
                                handleSaveQty(row.inbound_list_id, "Approved")
                              } // Pass "Approved" as status
                            >
                              Save
                            </button>
                          </td>

                          {/* <td className="th4">
                            {row.IsApproved === 1 ? (
                              <span>Approved</span>
                            ) : (
                              <>
                                <button
                                  className="technik-approve-btn"
                                  onClick={() =>
                                    handleApproval(
                                      row.inbound_list_id,
                                      "Approved"
                                    )
                                  } // Pass "Approved" as status
                                >
                                  Approve
                                </button>
                                <button
                                  className="technik-reject-btn"
                                  onClick={() =>
                                    handleApproval(row.inbound_list_id, "Hold")
                                  } // Pass "Hold" as status
                                >
                                  Reject
                                </button>
                              </>
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default TechnicalRequestListView;

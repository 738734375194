import React, { useState } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function DepartmentForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [departmentData, setDepartmentData] = useState({
    DepartmentName: "",
    DepartmentCode: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate("/department");
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(departmentData).forEach((key) => {
      if (!departmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });

    if (error) {
      showSnackbar("Required fields are empty");
      return; // Prevent further execution if there are validation errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_department`,
        departmentData, // Send departmentData directly
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Department added successfully");
        setTimeout(() => {
          navigate("/department");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving department:", error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Department
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="DepartmentName">
                    Department<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="DepartmentName"
                      value={departmentData.DepartmentName}
                      onChange={handleInputChange}
                      placeholder="Enter department"
                    />
                    {errors.DepartmentName && (
                      <div className="error-message">{errors.DepartmentName}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Department Code<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="DepartmentCode"
                      value={departmentData.DepartmentCode}
                      onChange={handleInputChange}
                      placeholder="Enter department code"
                    />
                    {errors.DepartmentCode && (
                      <div className="error-message">{errors.DepartmentCode}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DepartmentForm;

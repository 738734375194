import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditEquipmentSubType({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [equipmentSubTypeData, setEquipmentSubTypeData] = useState({
    EquipmentType: "",
    EquipmentSubType: "",
    EquipmentTypeId: "",
  });
  const [selectedEquipmentType, setSelectedEquipmentType] = useState("");
  const [severity, setSeverity] = useState("success");
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null);


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  useEffect(() => {
    const fetchEquipmentSubTypeData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_equipment_sub_type/${ID}`;

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setEquipmentSubTypeData({
            EquipmentType: data[0].EquipmentType,
            EquipmentTypeId: data[0].equipment_type_id,
            EquipmentSubType: data[0].EquipmentSubType,
          });
          setSelectedEquipmentTypes({
            label: data[0].EquipmentType,
            value: data[0].equipment_type_id,
          });
        } else {
          console.error("Failed to fetch sub-department");
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching sub-department:",
          error
        );
      }
    };

    fetchEquipmentSubTypeData();
  }, [ID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEquipmentSubTypeData({ ...equipmentSubTypeData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/equipment-sub-type");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasErrors = false;

    Object.keys(equipmentSubTypeData).forEach((key) => {
      if (!equipmentSubTypeData[key]) {
        errors[key] = `${key} is required`;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      showSnackbar("Required fields are empty", "warning");
      return;
    }

    const updatedEquipmentSubTypeData = {
      ...equipmentSubTypeData,
    
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_equipment_sub_type/${ID}`,
        updatedEquipmentSubTypeData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/equipment-sub-type");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating company:", error.message);
      setError("An error occurred while updating company data");
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/equipment-sub-type");
  };

  const handleEquipmentChange = (selectedOption) => {
    setSelectedEquipmentTypes(selectedOption);
    setEquipmentSubTypeData((prevFormData) => ({
      ...prevFormData,
      EquipmentTypeId: selectedOption.value,
      EquipmentType: selectedOption.label,
    }));
  };

  const fetchEquipmentType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the format expected by react-select
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Type:", error);
    }
  };

  useEffect(() => {
    fetchEquipmentType();
  }, []);

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Equipment Sub Type
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="EquipementType">
                    Equipment Type <span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipementType"
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentChange}
                    options={equipmentTypes}
                    placeholder="Select an equipment type"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Name">
                    Equipment Sub Type<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="EquipmentSubType"
                      value={equipmentSubTypeData.EquipmentSubType}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditEquipmentSubType;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditDesignation({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();

  const [designationData, setDesignationData] = useState({
    designation_name: "",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchDesignationData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_designation/${ID}`, {
          headers: {
            Authorization: authKey,
          },
        });
        console.log("API response:", response.data); 
        
        const responseData = response.data;
        
        if (Array.isArray(responseData)) {
          setDesignationData({
            designation_name: responseData[0]?.designation_name || "",
          });
        } else {
          setDesignationData({
            designation_name: responseData.designation_name,
          });
        }
        
        console.log("Designation data set:", responseData.designation_name); 
        setIsDataLoading(false);
      } catch (error) {
        console.error("Error fetching designation data:", error.message);
        setError("An error occurred while fetching designation data");
        setIsDataLoading(false);
      }
    };

    fetchDesignationData();
  }, [ID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDesignationData({ ...designationData, [name]: value });
  };

  const handleCancelClick = () => {
    navigate("/designations");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    if (!designationData.designation_name) {
      showSnackbar("Designation name is required", "warning");
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_designation/${ID}`,
        designationData,
        {
          headers: {
            Authorization: authKey,
          }
        }
      );

      if (response.status === 200) {
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/designations");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating designation:", error.message);
      setError("An error occurred while updating designation data");
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/designations");
  };

  if (isDataLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Designation
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="designation_name">
                    Designation<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="designation_name"
                      value={designationData.designation_name}
                      onChange={handleInputChange}
                      placeholder="Enter designation"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                type="button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditDesignation;

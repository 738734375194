import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./AmcSchedules.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Select, { components } from "react-select";

function AmcSchedulesForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedPODate, setSelectedPoDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [hasReminder, setHasReminder] = useState(false);

  const [daysBefore, setDaysBefore] = useState("");
  const [frequencyReminder, setFrequencyReminder] = useState("");
  const [amcData, setAmcData] = useState({
    amc_name: "",
    department_id: "",
    department_name: "",
    user_id: "",
    user_name: "",
    service_provider_name: "",
    service_provider_id: "",
    start_date: "",
    end_date: "",
    po_date: "",
    po_no: "",
    preventive_no: "",
    breakdown_no: "",
    services: "",
    days_before: "",
    has_reminder: "",
    frequency_reminder: "",
    schedule_start_date: "",
  });

  const handleRadioChange = (e) => {
    setHasReminder(e.target.value === "1");
    if (e.target.value === "0") {
      setDaysBefore("");
    }
  };

  const handleDaysBeforeChange = (e) => {
    setDaysBefore(e.target.value);
  };
  const handleReminderFrequencyChange = (e) => {
    setFrequencyReminder(e.target.value);
  };

  const handlePODateChange = (date) => {
    setSelectedPoDate(date);
    setAmcData((prevState) => ({
      ...prevState,
      po_date: date,
    }));
  };


  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setSelectedScheduleDate(date); 
    setAmcData((prevState) => ({
      ...prevState,
      schedule_start_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      schedule_start_date: null, 
    }));
  };


  const handleScheduleDateChange = (date) => {
    if (date < selectedStartDate || date > selectedEndDate) {
      // Show an error message or handle invalid date
      setErrors((prevErrors) => ({
        ...prevErrors,
        schedule_start_date:
          "Schedule start date must be between start date and end date",
      }));
    } else {
      // Clear any previous errors related to schedule start date
      setErrors((prevErrors) => ({
        ...prevErrors,
        schedule_start_date: null,
      }));

      setSelectedScheduleDate(date);
      setAmcData((prevState) => ({
        ...prevState,
        schedule_start_date: date,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setAmcData((prevState) => ({
      ...prevState,
      end_date: date,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAmcData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", 
    }));
  };

  const handleCancelClick = () => {
    navigate("/amc-configuration");
  };


  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const payload = {
        ...amcData,
        start_date: selectedStartDate ? format(selectedStartDate, "yyyy-MM-dd") : "",
        end_date: selectedEndDate ? format(selectedEndDate, "yyyy-MM-dd") : "",
        po_date: selectedPODate ? format(selectedPODate, "yyyy-MM-dd") : "",
        schedule_start_date: selectedScheduleDate ? format(selectedScheduleDate, "yyyy-MM-dd") : "",
        has_reminder: hasReminder ? "1" : "0",
        days_before: hasReminder ? daysBefore : "",
        frequency_reminder: hasReminder ? frequencyReminder : "",
      };
  
      const requiredFields = [
        'start_date',
        'end_date',
        'po_date',
        'schedule_start_date',
        'amc_name',
        'department_name',
        'user_name',
        'service_provider_name',
        'po_no',
        'preventive_no',
        'breakdown_no',
        'services',
      ];
  
      let validationErrors = {};
  
      requiredFields.forEach(field => {
        if (!payload[field]) {
          validationErrors[field] = "This field is required";
        }
      });
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setSeverity("error");
        showSnackbar("Please fill out all required fields.");
        setIsLoading(false);
        return;
      }
  
      // Append payload to FormData
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
  
      // Make API request
      const response = await axios.post(`${API_URL}/api/add_amc`, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("AMC added successfully");
        setTimeout(() => {
          navigate("/amc-configuration");
        }, 1000);
      }
    } catch (error) {
      // Handle API errors
      if (error.response && error.response.status === 500) {
        setSeverity("error");
        showSnackbar("Error saving AMC: Server error. Please try again.");
      } else {
        setSeverity("error");
        showSnackbar(`Error saving AMC: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setAmcData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
  };

  
  const fetchUserData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_user`, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const options = Array.isArray(data)
          ? data.map((user) => ({
              label: user.Name,
              value: user.ID,
            }))
          : [];
        setUsers(options);
      } else {
        console.error(`Error fetching user data: ${response.statusText}`);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setUsers([]);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchVendors = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      // Assuming response.data is already parsed as JSON
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setAmcData((prevFormData) => ({
      ...prevFormData,
      service_provider_name: selectedOption ? selectedOption.label : "",
      service_provider_id: selectedOption ? selectedOption.value : "",
    }));
  };
  
  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setAmcData((prevFormData) => ({
      ...prevFormData,
      user_name: selectedOption ? selectedOption.label : "",
      user_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleUserMenuOpen = () => {
    setUserMenuIsOpen(true);
  };
  
  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">New AMC</div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                  {errors.department && (
        <div className="error-message">{errors.department}</div>
    )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="user">
                    User<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="user"
                    options={users}
                    value={selectedUser}
                    onChange={handleUserChange}
                    // components={{ Control: CustomUserControl, Menu: CustomUserMenu }}
                    placeholder="Select User"
                    isSearchable
                    menuIsOpen={userMenuIsOpen}
                    onMenuOpen={handleUserMenuOpen}
                    onMenuClose={() => setUserMenuIsOpen(false)}
                  />
                   {errors.user && (
        <div className="error-message">{errors.user}</div>
    )}
                </div>
                <div className="form-group">
                  <label htmlFor="amc_name">
                    AMC Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="amc_name"
                      value={amcData.amc_name}
                      onChange={handleInputChange}
                      placeholder="Enter AMC name"
                    />
                    {errors.amc_name && (
                      <div className="error-message">{errors.amc_name}</div>
                    )}
                  </div>
                </div>

                <div className="form-group-select">
                  <label htmlFor="contractor_name">
                    Service Provider<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="contractor_name"
                    options={vendors}
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    placeholder="Select Service Provider"
                    isSearchable
                  />
                </div>
              
                
                <div className="form-group">
                  <label htmlFor="start_date">
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    {errors.start_date && (
                      <div className="error-message">{errors.start_date}</div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="end_date">
                    End Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="end_date"
                      id="end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select end date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    {errors.end_date && (
                      <div className="error-message">{errors.end_date}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="schedule_start_date">
                    Schedule Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedScheduleDate}
                      onChange={handleScheduleDateChange}
                      name="schedule_start_date"
                      id="schedule_start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select schedule start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      minDate={selectedStartDate} // Setting the minimum date for DatePicker
                      maxDate={selectedEndDate} // Setting the maximum date for DatePicker
                    />
                    {errors.end_date && (
                      <div className="error-message">{errors.end_date}</div>
                    )}
                  </div>
                </div>
             
                <div className="form-group">
                  <label htmlFor="po_no">
                    PO Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="po_no"
                      value={amcData.po_no}
                      onChange={handleInputChange}
                      placeholder="Enter po number"
                    />
                    {errors.amc_name && (
                      <div className="error-message">{errors.po_no}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="end_date">
                    PO Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedPODate}
                      onChange={handlePODateChange}
                      name="po_date"
                      id="po_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select po date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    {errors.end_date && (
                      <div className="error-message">{errors.po_date}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="preventive_no">
                    No. of Preventive Visits
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="preventive_no"
                      value={amcData.preventive_no}
                      onChange={handleInputChange}
                      placeholder="Enter no of preventive visits"
                    />
                    {errors.preventive_no && (
                      <div className="error-message">
                        {errors.preventive_no}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="breakdown_no">
                    No. of Breakdown Visits
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="breakdown_no"
                      value={amcData.breakdown_no}
                      onChange={handleInputChange}
                      placeholder="Enter no of breakdown visits"
                    />
                    {errors.breakdown_no && (
                      <div className="error-message">{errors.breakdown_no}</div>
                    )}
                  </div>
                </div>
                <div className="form-group msmme">
                  <label htmlFor="has_reminder">Set Reminder</label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="has_reminder"
                      value="1"
                      checked={hasReminder}
                      onChange={handleRadioChange}
                    />
                    <label>Yes</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="has_reminder"
                      value="0"
                      checked={!hasReminder}
                      onChange={handleRadioChange}
                    />
                    <label>No</label>
                  </div>
                </div>
                {hasReminder && (
                  <>
                    <div className="form-group ">
                      <label htmlFor="daysBefore">Days Before</label>
                      <div className="input-group">
                        <input
                          type="number"
                          value={daysBefore}
                          onChange={handleDaysBeforeChange}
                          min="1"
                          placeholder="Enter no of days"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="frequencyReminder">
                        Reminder Frequency
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          value={frequencyReminder}
                          onChange={handleReminderFrequencyChange}
                          min="1"
                          placeholder="Enter remider frequency in days"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label htmlFor="services">
                    Service Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="services"
                      rows={4}
                      value={amcData.services}
                      onChange={handleInputChange}
                      placeholder="Enter service description"
                    />
                    {errors.services && (
                      <div className="error-message">{errors.services}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )} */}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AmcSchedulesForm;

import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";

import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";

function UserView({ customerID, isSidebarExpanded, onClose }) {
    const [users, setUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUserID, setSelectedUserID] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [isSortPopup, setIsSortPopup] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [showActionHeader, setShowActionHeader] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const navigate = useNavigate();

    const [customerData, setCustomerData] = useState(null);

    const handleBackView = () => {
        onClose();
    };
    const handleClose = () => {
        onClose();
    }
    const handleEdit = () => {
        navigate(`/edit-customer/${customerID}`);
    };


    const togglePopup = async (customerID) => {
        try {

            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(`${API_URL}/api/get_customer/${customerID}`, {

                headers: {
                    Authorization: authKey,
                },
            });
            setCustomerData(response.data);
            setSelectedUserID(customerID);
        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const containerRef = useRef(null);

    useEffect(() => {
        fetchData();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsDeletePopup(false);
            setIsDeletePopupOpen(false);
            setIsSortPopup(false);
            setSelectedRowIndex(null);
        }
    };

    const handleDeletePopup = () => {
        setIsDeletePopup(!isDeletePopup);
        setIsDeletePopupOpen(false);
    };

    const handleDeleteButtonClick = () => {
        setIsDeletePopupOpen(!isDeletePopupOpen);
    };

    const handleCancel = () => {
        setIsDeletePopup(false);
    };

    const fetchData = async () => {
        try {

            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/api/get_customers`, {
                headers: {
                    Authorization: authKey,
                },
            });

            setUsers(response.data);

        } catch (error) {
            console.error("Error fetching user data:", error.message);

        }
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
        setChecked(newSelectAll);
    };

    const handleSelectRow = (customerID) => {
        const updatedSelectedRows = selectedRows.includes(customerID)
            ? selectedRows.filter((id) => id !== customerID)
            : [...selectedRows, customerID];

        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === users.length);
        setChecked(updatedSelectedRows.length > 0);
    };
    const handleConfirmDelete = async () => {
    };

    const handleCloseButtonClick = () => {
        setChecked(false);
        setSelectedRows([]);
        setShowActionHeader(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const authKey = localStorage.getItem("authKey");
                const response = await axios.get(`${API_URL}/api/get_customer/${customerID}`, {
                    headers: {
                        Authorization: authKey,
                    },
                });
    
                // Set customerData directly since the response is an object
                setCustomerData(response.data);
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };
    
        if (customerID) {
            fetchUserData();
        }
    }, [customerID]);
    


    return (
        <>
            <div
                className={`main-container page-content${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div
                    className={`data-lists-content-container page-contents ${isSidebarExpanded ? "expanded" : "collapsed"
                        }`}
                >
                    <div className="data-list-content" ref={containerRef}>
                        {isChecked ? (
                            <div className="action-header">
                                <div className="action-header-content">
                                    <div className="action-header-content-leftside">
                                        <div className="action-button">
                                            <button className="print">
                                                <IoPrintOutline className="print-icon" />
                                            </button>
                                            <button className="bulk-update">Bulk Update</button>
                                            <button
                                                className="delete"
                                                onClick={handleDeleteButtonClick}
                                            >
                                                <HiOutlineDotsHorizontal className="delete-icon" />
                                            </button>
                                            {isDeletePopupOpen && (
                                                <div className="delete-popup">
                                                    <div className="delete-popup-content">
                                                        <button onClick={handleDeletePopup}>
                                                            Move to archive
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {isDeletePopup && (
                                                <div className="confirm-delete-popup">
                                                    <div className="confirm-delete-popup-content">
                                                        <div className="confirm-model">
                                                            <div className="confirm-model-content">
                                                                <div className="confirm-model-content-body">
                                                                    <p>Are you sure want to move archive?</p>
                                                                </div>
                                                                <div className="confirm-model-content-footer">
                                                                    <button
                                                                        className="confirm-delete-button"
                                                                        onClick={handleConfirmDelete}
                                                                    >
                                                                        Yes
                                                                    </button>
                                                                    <button
                                                                        className="confirm-cancel-button"
                                                                        onClick={handleCancel}
                                                                    >
                                                                        No
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="action-header-content-rightside">
                                        <button
                                            className="action-header-close-button"
                                            onClick={handleCloseButtonClick}
                                        >
                                            <IoCloseOutline className="action-header-close-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="data-list-header">
                                <div className="data-list-header-content">
                                    <div className="data-list-heading-content-left-side">
                                        All Customers
                                    </div>
                                    <div className="data-list-heading-content-right-side">
                                        <Link to="/userform" className="data-list-new">
                                            <Tooltip title="New User" arrow>
                                                <button className="new-button">
                                                    <HiOutlinePlus className="new-plus-icon" />
                                                </button>
                                            </Tooltip>
                                        </Link>


                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="data-list-body">
                            <div className="data-list-scroll-body">
                                <div className="data-list-table">



                                    <table className="table multilevel-data">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                    />
                                                </th>
                                                <th>CUSTOMER NAME</th>
                                            </tr>
                                        </thead>
                                        <tbody className="data-list-scroll-bodys">
                                            {users().map((customer, index) => (
                                                <tr key={customer.ID} className="hoverable-row">
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRows.includes(customer.ID)}
                                                            onChange={() => handleSelectRow(customer.ID)}
                                                        />
                                                    </td>

                                                    <td onClick={() => togglePopup(customer.id)} className={selectedUserID === customer.id ? 'selected-user' : ''} style={{ cursor: "pointer", color: "blue" }}>
                                                        {customer.customer_name || "NA"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="data-view-popup">
                    <div className="data-popup-content">
                        <div className="data-view">
                            <div className="data-view-contant">
                                <div className="data-view-header">
                                    <div className="data-view-header-left-side">
                                        {customerData && (
                                            <div className="user-header-title">
                                                <div>
                                                    <Tooltip title="Back" arrow>  <IoMdArrowBack className="back-company" onClick={handleBackView} /> </Tooltip>
                                                    {customerData.customer_name || "NA"}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-view-header-right-side">
                                        <div className="header-title-edit">
                                            <button className='edit-header-button' onClick={handleEdit}><MdOutlineModeEditOutline className="edit-view-icon" />Edit</button>
                                            <div className="view-close-button" onClick={handleClose} >
                                                <IoCloseOutline />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="user-view-body">
                                    <div className="rows">
                                        {customerData && (
                                            <div className="user-view-body-container">
                                                <div className="user-view-body-container-content">
                                                    <div className="text-medium-header">Customer Details</div>
                                                    <div className="text-medium-body">
                                                        <div className="row">
                                                            <label htmlFor="">Customer Name</label>
                                                            <div className="answer">{customerData.customer_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Contact No</label>
                                                            <div className="answer">{customerData.contact_no || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Address</label>
                                                            <div className="answer">{customerData.address || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Location</label>
                                                            <div className="answer">{customerData.locations ? customerData.locations.map((loc, index) => (
                                                                <div key={index}>
                                                                    {loc.address || "NA"}
                                                                </div>
                                                            )) : "NA"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default UserView;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

function EditActualManpower({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectId, setProjectId] = useState("");
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    // man_power_id: id || "",
    person_name: "",
    contact_no: "",
    email: "",
    status: "",
    // qualification: "",
    // passing_year: "",
    joining_expected_date: "",
    position: "",
  });
  const [positionOptions, setPositionOptions] = useState([]);

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchActualManpowerData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_actual_manpower/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;
        const formattedJoiningDate = new Date(
          responseData.joining_expected_date
        )
          .toISOString()
          .split("T")[0];

        const formattedData = {
          person_name: responseData.person_name || "",
          contact_no: responseData.contact_no || "",
          email: responseData.email || "",
          status: responseData.status || "",
          position: responseData.position || "",
          joining_expected_date: formattedJoiningDate || "",
          project_id: responseData.project_id || "", // Added project_id
          man_power_id: responseData.man_power_id || "", // Added man_power_id
        };
        setManPowerData(formattedData);
        setProjectId(responseData.project_id || ""); // Store project_id in state

        // Initialize status and date fields
        setSelectedStatus(
          statusOptions.find(
            (option) => option.value === formattedData.status
          ) || null
        );
        setSelectedPosition(
          positionOptions.find(
            (option) => option.label === formattedData.position
          ) || null
        );
        setSelectedDate(new Date(formattedData.joining_expected_date));
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchActualManpowerData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManPowerData({ ...manPowerData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate(`/actualmanpower/${projectId}`);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {

  //     try {
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.put(
  //         `${API_URL}/api/update_actual_manpower/${id}`,
  //         manPowerData,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Data updated successfully");
  //         setTimeout(() => {
  //           navigate("/department");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error updating department:", error.message);
  //       setError("An error occurred while updating department data");
  //       setIsLoading(false);
  //     }

  // };
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);

      // Ensure required fields are included in the payload
      const payload = {
        ...manPowerData,
        // position: selectedPosition ? selectedPosition.label : null,
        man_power_id: selectedPosition ? selectedPosition.value : null,
      };

      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_actual_manpower/${id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate(`/actualmanpower/${projectId}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating department:", error.message);
      setError("An error occurred while updating department data");
      setIsLoading(false);
    }
  };

  const statusOptions = [
    { value: "Did not join", label: "Did not join" },
    { value: "Present at site", label: "Present at site" },
    { value: "Resigned", label: "Resigned" },
    {
      value: "Appointment issued (yet to join)",
      label: "Appointment issued (yet to join)",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setManPowerData((prevState) => ({
      ...prevState,
      status: selectedOption ? selectedOption.value : "",
    }));
    // setErrors((prevState) => ({
    //   ...prevState,
    //   status: "", // Clear the error message for this field
    // }));
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        joining_expected_date: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        passing_year: year,
      }));
    }
  };
  const handleBackProject = () => {
    navigate(`/actualmanpower/${projectId}`);
  };

  // const fetchPositionData = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.get(
  //       `${API_URL}/api/manpower_requirements/${projectId}`,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     // Filter positions by project_id stored in state
  //     const filteredOptions = response.data
  //       .filter((item) => item.project_id === projectId) // Use projectId from state
  //       .map((item) => ({
  //         value: item.id,
  //         label: item.position, // Customize the label as needed
  //         project_id: item.project_id,
  //       }));

  //     setPositionOptions(filteredOptions);
  //   } catch (error) {
  //     console.error("Error fetching position data:", error.message);
  //   }
  // };

  useEffect(() => {
    if (projectId) {
      // Only fetch position data if project_id is available
      fetchPositionData();
    }
  }, [projectId]);

  const fetchPositionData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/manpower_requirements/${projectId}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      // Map fetched data to the required format for Select options
      const filteredOptions = response.data.Items
        .filter((item) => item.project_id === projectId) // Filter based on projectId
        .map((item) => ({
          value: item.id,
          label: item.position,
        }));
  
      setPositionOptions(filteredOptions);
  
      // If the fetched position matches the current manPowerData, set it as selected
      const matchedPosition = filteredOptions.find(
        (option) => option.label === manPowerData.position
      );
      if (matchedPosition) {
        setSelectedPosition(matchedPosition);
      }
    } catch (error) {
      console.error("Error fetching position data:", error.message);
    }
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Actual Manpower
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="person_name">
                    Person Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="person_name"
                      value={manPowerData.person_name}
                      onChange={handleInputChange}
                      placeholder="Enter person name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={manPowerData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact number"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      value={manPowerData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="position">
                    Position<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentSubType"
                    value={selectedPosition}
                    options={positionOptions}
                    placeholder="Select position"
                    isSearchable
                    onChange={setSelectedPosition}
                   
                  />
                </div>

                <div className="form-group-select">
                  <label htmlFor="status">
                    Current Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="currentStatus"
                    value={selectedStatus}
                    onChange={handleChange}
                    options={statusOptions}
                    placeholder="Select status..."
                    isClearable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="joining_expected_date">
                    Expected Date of Joining{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="joining_expected_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="datesss"
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setSelectedDate(null);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditActualManpower;

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AllPreventiveSchedules.css";
import Pagination from "../Component/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";
import { format } from 'date-fns';
import { Typography, Modal, Paper } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { debounce } from 'lodash';

function AllPreventiveSchedules({ isSidebarExpanded }) {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalSchedules, setTotalSchedules] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [dueToday, setDueToday] = useState(0); // State for due today
  const [dueTomorrow, setDueTomorrow] = useState(0); // State for due tomorrow
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [selectedScheduleStatus, setSelectedScheduleStatus] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState(localStorage.getItem("selectedFilter") || "all");

  const [statusCounts, setStatusCounts] = useState({
    Cancelled: 0,
    Completed: 0,
    Hold: 0,
    active: 0,
    overdue: 0,
    "In Progress": 0,
    "not in tenure": 0,
  });
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  const handleOpenModal = (schedule) => {
    setSelectedSchedule(schedule);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSchedule(null);
    setModalOpen(false);
  };

  const formatDate = (date) => {
    if (!date) return "N/A";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "N/A";

    return parsedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Current Week", value: "current_week" },
    { label: "Past Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];

  const statusOptions = [
    { label: "All Status", value: "all" },
    { label: "Active", value: "active" },
    { label: "Completed", value: "Completed" },
    { label: "Hold", value: "hold" },
    { label: "Overdue", value: "overdue" },
    { label: "Not in Tenure", value: "not in tenure" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  const debouncedFetchSchedules = useCallback(
    debounce((filter, status, query, page, perPage, startDate, endDate) => {
      fetchSchedulesByFilterAndStatus(filter, status, query, page, perPage, startDate, endDate);
    }, 100), []
  );

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(value);
    localStorage.setItem("selectedFilter", value);

    if (value !== "custom_date" || (selectedStartDate && selectedEndDate)) {

    }
  };

  const handleScheduleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedScheduleStatus(value);
    localStorage.setItem("selectedScheduleStatus", value);
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);

    const formattedStartDate = start ? format(start, 'yyyy-MM-dd') : null;
    const formattedEndDate = end ? format(end, 'yyyy-MM-dd') : null;

    localStorage.setItem("selectedStartDate", formattedStartDate);
    localStorage.setItem("selectedEndDate", formattedEndDate);

    if (formattedStartDate && formattedEndDate) {

    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  // Handle search input change
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedFetchSchedules(selectedFilter, selectedScheduleStatus, value, page, itemsPerPage, selectedStartDate, selectedEndDate);
  };

  const fetchSchedulesByFilterAndStatus = async (filter, status, query, page, perPage, startDate, endDate) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/filter_scheduler_with_search`;

      const params = {
        status,
        page,
        per_page: perPage,
        query: query,
      };

      if (filter !== "custom_date") {
        params.filter_option = filter;
      } else if (startDate && endDate) {
        params.start_date = format(new Date(startDate), 'yyyy-MM-dd');
        params.end_date = format(new Date(endDate), 'yyyy-MM-dd');
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params,
      });

      if (response.status === 200) {
        setSchedules(response.data.schedulers);
        setTotalItems(response.data.total);
        setLoading(false);
      } else {
        console.error("Error fetching scheduler data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedFilter = localStorage.getItem("selectedFilter") || "all";
    const storedStatus = localStorage.getItem("selectedScheduleStatus") || "";
    const storedStartDate = localStorage.getItem("selectedStartDate") || null;
    const storedEndDate = localStorage.getItem("selectedEndDate") || null;
    const storedPage = localStorage.getItem("currentPage") || 1;
    const storedItemsPerPage = localStorage.getItem("itemsPerPage") || 50;

    setSelectedFilter(storedFilter);
    setSelectedScheduleStatus(storedStatus);
    setSelectedStartDate(storedStartDate ? new Date(storedStartDate) : null);
    setSelectedEndDate(storedEndDate ? new Date(storedEndDate) : null);
    setPage(parseInt(storedPage, 10));
    setItemsPerPage(parseInt(storedItemsPerPage, 10));

    // Trigger API call when all relevant states have settled
    debouncedFetchSchedules(storedFilter, storedStatus, searchInput, storedPage, storedItemsPerPage, storedStartDate, storedEndDate);
  }, []);


  useEffect(() => {
    if (selectedFilter && selectedScheduleStatus) {
      debouncedFetchSchedules(selectedFilter, selectedScheduleStatus, searchInput, page, itemsPerPage, selectedStartDate, selectedEndDate);
    }
  }, [selectedFilter, selectedScheduleStatus, searchInput, page, itemsPerPage, selectedStartDate, selectedEndDate]);

  const containerRef = useRef(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  const fetchStatusData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/scheduler_summary`, {
        headers: {
          Authorization: authKey,
        },
      });

      const data = response.data;
      if (data) {
        setDueToday(data.due_today || 0);
        setDueTomorrow(data.due_tomorrow || 0);
        setStatusCounts(data.status_counts || {});
        setTotalSchedules(data.total_schedules || null);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatusData();
  }, []);


  const fetchData = async (page, perPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_scheduler`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: Number(page),
          per_page: Number(perPage),
        },
      });

      setSchedules(response.data.schedulers);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };


  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? schedules.map((schedule) => schedule.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === schedules.length);
    setChecked(updatedSelectedRows.length > 0);
  };

  const handleArchiveschedule = async () => { };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleRefresh = () => {
    // Reset all relevant states
    setSelectedScheduleStatus("all");
    setSelectedFilter("all");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSearchInput("");
    setPage(1);
    setItemsPerPage(50);
    // Clear corresponding localStorage items
    localStorage.removeItem("selectedFilter");
    localStorage.removeItem("selectedStatus");
    localStorage.removeItem("selectedStartDate");
    localStorage.removeItem("selectedEndDate");
    localStorage.removeItem("searchInput");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("itemsPerPage");


    fetchData(1, 50);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleViewClick = async (scheduler_id) => {
    navigate(`/scheduler-view/${scheduler_id}`);
  };



  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveschedule}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Schedules
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="project-dropdown-schedules">
                      <FormControl fullWidth>
                        <InputLabel id="filter-select-label">Status</InputLabel>
                        <Select
                          labelId="filter-select-label"
                          id="filter-select"
                          value={selectedScheduleStatus}
                          onChange={handleScheduleStatusChange}
                          label="Status"
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="project-dropdown-schedules">
                      <FormControl fullWidth>
                        <InputLabel id="filter-select-label">Filter</InputLabel>
                        <Select
                          labelId="filter-select-label"
                          id="filter-select"
                          value={selectedFilter}
                          onChange={handleFilterChange}
                          label="Filter"
                        >
                          {filterOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {selectedFilter === "custom_date" && (
                      <div className="input-group-datepickers">
                        <DatePicker
                          placeholderText="Select Date Range"
                          className="dates"
                          startDate={selectedStartDate}
                          endDate={selectedEndDate}
                          selectsRange={true}
                          isClearable
                          dateFormat="yyyy-MM-dd"
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          onChange={handleDateRangeChange}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField {...startProps} variant="standard" />
                              <TextField {...endProps} variant="standard" />
                            </>
                          )}
                          startText="Start Date"
                          endText="End Date"
                        />
                      </div>
                    )}
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="entity-dashboard">
              <div className="entity-dashboard-contant">
                <div className="entity-dashboard-contant-item">
                  <div className="entity-icon"></div>
                  <div className="entity-text-left">
                    <div className="entity-text">
                      <div className="entity-text-title">Total Schedules</div>
                      <div
                        className="entity-text-title-total"
                        onClick={fetchData}
                      >
                        {totalSchedules}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entity-text-right">
                  <div className="entity-text">
                    <div className="entity-text-title">Due Today</div>
                    <div
                      className="entity-text-title-total "
                    // onClick={() => fetchSchedulesByDateType("today")}
                    >
                      {dueToday || "0"}
                    </div>
                  </div>{" "}
                  <div className="entity-text">
                    <div className="entity-text-title">Due Tomorrow</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {dueTomorrow || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">Overdue</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.overdue || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">Completed</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.Completed || "0"}
                    </div>
                  </div>{" "}
                  <div className="entity-text">
                    <div className="entity-text-title">Hold</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.Hold || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">In Progress</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.Hold || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">Active</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.active || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">Cancelled</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts.Cancelled || "0"}
                    </div>
                  </div>
                  <div className="entity-text">
                    <div className="entity-text-title">Not in Tenure</div>
                    <div
                      className="entity-text-title-total"
                    >
                      {statusCounts["not in tenure"] || "0"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-bodys">
                <div className="data-list-tables">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : schedules.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>TAG NO</th>
                          <th>DEPARTMENT</th>
                          <th>FREQUENCY</th>
                          <th>FREQUENCY TYPE</th>
                          <th>STATUS</th>
                          <th>SERVICE DUE DATE</th>
                          <th>LAST SERVICED DATE</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {schedules.map((schedule, index) => (
                          <tr key={index} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(schedule.id)}
                                onChange={() => handleSelectRow(schedule.id)}
                              />
                            </td>

                            <td>{schedule.serial_no}</td>
                            <Tooltip title="Click here to show details">
                              <td
                                className="active-td"
                                onClick={() => handleOpenModal(schedule)}
                              >
                                {schedule.equipment_tag_no || "NA"}
                              </td>
                            </Tooltip>
                            <td>{schedule.department || "NA"}</td>
                            <td>{schedule.frequency || "NA"} Days</td>
                            <td>
                              {schedule.frequency_type
                                ? schedule.frequency_type === "factory_act"
                                  ? "Factory Act"
                                  : schedule.frequency_type === "peso"
                                    ? "Peso"
                                    : schedule.frequency_type === "oisd"
                                      ? "OISD"
                                      : schedule.frequency_type === "oem"
                                        ? "OEM"
                                        : schedule.frequency_type 
                                : "NA"}
                            </td>

                            {/* <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    schedule.status === "Hold"
                                      ? "#D4AC0D"
                                      : schedule.status === "Cancelled"
                                        ? "#F81700"
                                        : schedule.status === "not in tenure"
                                          ? "#313639"
                                          : schedule.status === "overdue"
                                            ? "#C0612B"
                                            : schedule.status === "active"
                                              ? "#2980B9"
                                              : schedule.status === "In Progress"
                                                ? "#df7f26"
                                                : schedule.status === "Completed"
                                                  ? "#2E8B57"
                                                  : "transparent",
                                }}
                              >
                                {schedule.status || "NA"}
                              </div>
                            </td> */}

                            <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    schedule.status === "Hold"
                                      ? "#D4AC0D"
                                      : schedule.status === "Cancelled"
                                        ? "#F81700"
                                        : schedule.status === "not in tenure"
                                          ? "#313639"
                                          : schedule.status === "overdue"
                                            ? "#C0612B"
                                            : schedule.status === "active"
                                              ? "#2980B9"
                                              : schedule.status === "In Progress"
                                                ? "#df7f26"
                                                : schedule.status === "Completed"
                                                  ? "#2E8B57"
                                                  : "transparent",
                                  width: "6vw",
                                }}
                              >
                                {schedule.status
                                  ? schedule.status.charAt(0).toUpperCase() + schedule.status.slice(1).toLowerCase()
                                  : "NA"}
                              </div>
                            </td>

                            <td>{formatDate(schedule.service_due_date)}</td>
                            <td>{formatDate(schedule.last_serviced_date)}</td>
                            <td>
                              <button
                                onClick={() => handleViewClick(schedule.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Schedules Found</div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Paper className="modal-container">
            <div className="modal-header">
              <Typography variant="h6">Equipment Details</Typography>
            </div>
            <div className="modal-body">
              {selectedSchedule && (
                <div>
                  <p>Category: {selectedSchedule.category}</p>
                  <p>Make: {selectedSchedule.Make}</p>
                  <p>Model: {selectedSchedule.Model}</p>
                  <p>
                    Year of Installation: {selectedSchedule.InstallationYear}
                  </p>
                  <p>Warranty Expiry: {formatDate(selectedSchedule.WarrantyExp)}</p>
                  {/* Add more details as needed */}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <Button variant="contained" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Paper>
        </Modal>
      </div>
    </>
  );
}

export default AllPreventiveSchedules;

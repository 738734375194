import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditEquipmentSubTypeVariant({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [equipmentData, setEquipmentData] = useState({
    EquipmentSubType: "",
      EquipmentSubTypeId: null,
      VariantName: null,
      ApplicableOISD: "",
      Subsection: "",
      DepartmentId: "",
      DepartmentName: "",
      EquipmentType: "",
      EquipmentTypeId: "",
      SubDepartmentId: "",
      SubDepartmentName: "",

  });
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [selectedEquipmentSubTypeVariants, setSelectedEquipmentSubTypeVariants] = useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [errors, setErrors] = useState({
    
  });

 
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
 
  useEffect(() => {
    const fetchSubTypeVariantData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_sub_type_variant/${ID}`, {
          headers: { Authorization: authKey },
        });
        if (response.data.length > 0) {
          const responseData = response.data[0];
          setEquipmentData({
            ApplicableOISD: responseData.ApplicableOISD || "",
            Subsection: responseData.Subsection || "",
            Name: responseData.VariantName || "",
            DepartmentName: responseData.DepartmentName || "",
            SubDepartmentName: responseData.SubDepartmentName || "",
        
          });
          setSelectedDepartment({ label: responseData.DepartmentName, value: responseData.department_id });
          setSelectedEquipmentSubType({ label: responseData.EquipmentSubType, value: responseData.EquipmentSubType });
          setSelectedSubDepartment({ label: responseData.SubDepartmentName, value: responseData.sub_department_id });
          setSelectedEquipmentTypes({ label: responseData.EquipmentType, value: responseData.equipment_type_id });
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error("Error fetching equipment sub type variant data:", error.message);
        setError("An error occurred while fetching equipment sub type variant data");
      }
    };
    fetchSubTypeVariantData();
  }, [ID]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentData(prevData => ({
      ...prevData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
    setSelectedEquipmentTypes(null);
    setSelectedEquipmentSubType(null);
    setSelectedEquipmentSubTypeVariants(null);
   
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_departments`, {
        headers: { Authorization: authKey },
      });
      if (response.status === 200) {
        const options = response.data.map(department => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchSubDepartments = async () => {
    if (selectedDepartment) {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_sub_department/${selectedDepartment.value}`, {
          headers: { Authorization: authKey },
        });
        if (response.status === 200) {
          const options = response.data.map(subDepartment => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } catch (error) {
        console.error("An error occurred while fetching sub-departments:", error);
      }
    } else {
      setSubDepartments([]);
      setSelectedSubDepartment(null);
    }
  };

  useEffect(() => {
    fetchSubDepartments();
  }, [selectedDepartment]);

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`, {
        headers: { Authorization: authKey },
      });
      if (response.status === 200) {
        const options = response.data.map(equipmentType => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  useEffect(() => {
    if (selectedSubDepartment) {
      fetchEquipmentTypes(selectedSubDepartment.value);
    }
  }, [selectedSubDepartment]);

  const fetchEquipmentSubType = async (equipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_equipment_sub_types_by_type/${equipmentTypeId}`, {
        headers: { Authorization: authKey },
      });
      if (response.status === 200) {
        const options = response.data.map(equipmentSubType => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Sub Type:", error);
    }
  };

  useEffect(() => {
    if (selectedEquipmentTypes) {
      fetchEquipmentSubType(selectedEquipmentTypes.value);
    }
  }, [selectedEquipmentTypes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEquipmentData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    navigate("/equipment-sub-type-variant");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;

  //   if (!error) {
  //     try {
  //       const updatedData = {
  //         ...equipmentData,
  //         EquipmentSubType: selectedEquipmentSubType?.label || "", // Add Equipment Sub Type to the payload
  //       };
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");

  //       const response = await axios.put(`${API_URL}/api/update_sub_type_variant/${ID}`, updatedData, {
  //         headers: { Authorization: authKey },
  //       });

  //       if (response.status === 200) {
  //         showSnackbar("Equipment Sub Type Variant updated successfully");
  //         navigate("/equipment-sub-type-variant");
  //       } else {
  //         showSnackbar("Failed to update Equipment Sub Type Variant");
  //       }
  //     } catch (error) {
  //       console.error("Error updating equipment sub type variant:", error);
  //       showSnackbar("An error occurred while updating equipment sub type variant");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };


  const handleSaveClick = async () => {
    const newErrors = {};
  
    // Validation for required fields
    if (!selectedDepartment) {
      newErrors.department = "Department is required";
    }
    if (!selectedSubDepartment) {
      newErrors.subDepartment = "Sub Department is required";
    }
    if (!selectedEquipmentTypes) {
      newErrors.equipmentType = "Equipment Type is required";
    }
    if (!selectedEquipmentSubType) {
      newErrors.equipmentSubType = "Equipment Sub Type is required";
    }
    if (!equipmentData.Name) {
      newErrors.name = "Sub Type Variant Name is required";
    }
    if (!equipmentData.ApplicableOISD) {
      newErrors.applicableOISD = "Applicable OISD is required";
    }
    if (!equipmentData.Subsection) {
      newErrors.subsection = "Subsection is required";
    }
  
    // If there are any errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // No errors, proceed with the API call
    try {
      const updatedData = {
        ...equipmentData,
        EquipmentSubType: selectedEquipmentSubType?.label || "", // Add Equipment Sub Type to the payload
      };
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(`${API_URL}/api/update_sub_type_variant/${ID}`, updatedData, {
        headers: { Authorization: authKey },
      });
  
      if (response.status === 200) {
        showSnackbar("Equipment Sub Type Variant updated successfully");
        navigate("/equipment-sub-type-variant");
      } else {
        showSnackbar("Failed to update Equipment Sub Type Variant");
      }
    } catch (error) {
      console.error("Error updating equipment sub type variant:", error);
      showSnackbar("An error occurred while updating equipment sub type variant");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleBackProject = () => {
    navigate("/equipment-sub-type-variant");
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    fetchSubDepartments(selectedOption.value);
    setSelectedSubDepartment(null);
    setSelectedEquipmentTypes(null);
    setSelectedEquipmentSubType(null);
  };
  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
  };
  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectedEquipmentTypes(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
    setSelectedEquipmentSubType(null);
    // setSelectedEquipmentTypes(null);
  };


  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Equipment Sub Type variant
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
              <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    value={selectedDepartment}
                    // onInputChange={handleInputChanges}
                    // onKeyDown={handleKeyDown}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select a department"

                  />
                   {errors.department  && <div className="error-message">{errors.department}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedSubDepartment} // Corrected variable name
                    onChange={handleSubDepartmentChange}
                    options={subDepartments}
                    // onKeyDown={handleSubDepartmentKeyDown}
                    // onInputChange={handleInputChanges}
                    placeholder="Select sub department"
                  />
                   {errors.subDepartment  && <div className="error-message">{errors.subDepartment}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentType">
                    Equipment Type<span className="text-danger">*</span>{" "}
                  </label>

                  <Select
                    value={selectedEquipmentTypes} // Corrected variable name
                    onChange={handleEquipmentTypeChange}
                    options={equipmentTypes}
                    // onKeyDown={handleTypeKeyDown}
                    // onInputChange={handleInputChanges}
                    placeholder="Select equipment type"
                  />
                   {errors.equipmentType   && <div className="error-message">{errors.equipmentType}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubType">
                    Equipment Sub Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    options={equipmentSubTypes}
                    // onKeyDown={handleSubTypeKeyDown}
                    // onInputChange={handleInputChanges}
                    placeholder="Select equipment sub type"
                  />
                   {errors.equipmentSubType   && <div className="error-message">{errors.equipmentSubType}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="Name">
                    Sub Type Variant Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Name"
                      value={equipmentData.Name || ""}
                      onChange={handleInputChange}
                    />
                    
                  </div>
                  {errors.name   && <div className="error-message">{errors.name  }</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="ApplicableOISD">
                    Applicable OISD<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="ApplicableOISD"
                      value={equipmentData.ApplicableOISD || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.applicableOISD   && <div className="error-message">{errors.applicableOISD  }</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="Subsection">
                    Subsection<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Subsection"
                      value={equipmentData.Subsection || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.subsection  && <div className="error-message">{errors.subsection }</div>}
                </div>
                {/* <div className="form-group">
                  <label htmlFor="Frequency">
                    Frequency<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Frequency"
                      value={equipmentData.Frequency || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </div> */}
              </div>
            </form>
            
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditEquipmentSubTypeVariant;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import "./PrVendorDetails.css";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { FiUpload } from "react-icons/fi";

function PrVendorDetails({ isSidebarExpanded }) {
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [quotationHistory, setQuotationHistory] = useState([]);
  const [quotationData, setQuotationData] = useState({
    date: "",
    description: "",
    attachments: [],
  });

  const navigate = useNavigate();
  const { vendor_id } = useParams();
  const location = useLocation();
  const { pr_id } = location.state || {};
  console.log("Received pr_id:", pr_id);

  const handleBackClick = () => {
    if (pr_id) {
      navigate(`/approved-pr-view/${pr_id}`);
    } else {
      navigate("/approved-pr-requests");
    }
  };

  useEffect(() => {
    fetchQuotationHistory();
  }, [vendor_id]);

  const handleFileChange = (e) => {
    setAttachments([...e.target.files]);
  };

  //   const handleSaveClick = async () => {
  //     // Validate the quotationData before submission
  //     if (!quotationData.date) {
  //       setSnackbarMessage("Date is required");
  //       setSeverity("warning");
  //       setSnackbarOpen(true);
  //       return;
  //     }

  //     try {
  //       setIsLoading(true);
  //       const formData = new FormData();
  //       formData.append("vendor_id", vendor_id);
  //       formData.append("date", quotationData.date);
  //       formData.append("description", quotationData.description);

  //       // Append attachments
  //       attachments.forEach((file) => {
  //         formData.append("attachments", file); // Append each file
  //       });

  //       const response = await axios.post(
  //         `${API_URL}/api/add_vendor_quotation_history`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: localStorage.getItem("authKey"),
  //           },
  //         }
  //       );

  //       if (response.status === 201) {
  //         setSnackbarMessage("Vendor Quotation History added successfully");
  //         setSeverity("success");
  //         setSnackbarOpen(true);
  //         fetchQuotationHistory();

  //         // Reset form fields after successful submission
  //         setQuotationData({ date: "", description: "" });
  //         setAttachments([]); // Clear attachments
  //       }
  //     } catch (error) {
  //       console.error("Error saving vendor quotation history:", error);
  //       setSnackbarMessage("Error adding quotation history");
  //       setSeverity("error");
  //       setSnackbarOpen(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  const handleSaveClick = async () => {
    // Validate the quotationData before submission
    if (!quotationData.date) {
      setSnackbarMessage("Date is required");
      setSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("vendor_id", vendor_id);
      formData.append("date", quotationData.date);
      formData.append("description", quotationData.description);

      // Append attachments
      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file); // Directly append file to attachments
        });
      }

      const response = await axios.post(
        `${API_URL}/api/add_vendor_quotation_history`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("authKey"),
          },
        }
      );

      if (response.status === 201) {
        setSnackbarMessage("Vendor Quotation History added successfully");
        setSeverity("success");
        setSnackbarOpen(true);
        fetchQuotationHistory();

        // Reset form fields after successful submission
        setQuotationData({ date: "", description: "" });
        setAttachments([]); // Clear attachments
        setSelectedFiles([]); // Clear selected files display
      }
    } catch (error) {
      console.error("Error saving vendor quotation history:", error);
      setSnackbarMessage("Error adding quotation history");
      setSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuotationHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/get_vendor_quotation_history/${vendor_id}`,
        {
          headers: { Authorization: localStorage.getItem("authKey") },
        }
      );
      setQuotationHistory(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendor quotation history:", error);
      setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackClick}
                  />
                </Tooltip>
                New Vendor Quotation
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="pr-form">
              <form className="form-detail">
                <div className="new-data-form">
                  <div className="form-group">
                    <label htmlFor="date">
                      Date<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        name="date"
                        value={quotationData.date}
                        onChange={(e) =>
                          setQuotationData({
                            ...quotationData,
                            date: e.target.value,
                          })
                        }
                        placeholder="Enter date"
                        className="your-custom-classname-date"
                      />
                      {errors.date && (
                        <div className="error-message">{errors.date}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <div className="input-group">
                      <textarea
                        name="Description"
                        id="Description"
                        cols="35"
                        rows="3"
                        placeholder="Enter description"
                        value={quotationData.description}
                        onChange={(e) =>
                          setQuotationData({
                            ...quotationData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="attachments">Attachments</label>
                    <div className="input-groups">
                      {/* <input
                        type="file"
                        multiple
                        name="attachments"
                        onChange={handleFileChange}
                      /> */}

                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        id="attachments"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <div className="attchment-file-lists">
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {selectedFiles.map((file, index) => (
                              <li key={index}>{file.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="history-button">
                <div className="button-group">
                  <button
                    className="data-form-save-button"
                    type="button"
                    onClick={handleSaveClick}
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save"}
                  </button>
                  <button
                    className="data-form-cancel-button"
                    onClick={handleBackClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* Quotation History Table */}
            <div className="quotation-history">
              <h2>Quotation History</h2>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      {/* <div className="loader"></div>
                      <div>Loading...</div> */}
                    </div>
                  ) : quotationHistory.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>SR. NO.</th>
                          <th>DATE</th>
                          <th>DESCRIPTION</th>
                          <th>ATTACHMENTS</th>
                        </tr>
                      </thead>

                      <tbody>
                        {quotationHistory.length > 0 &&
                          quotationHistory.map((quotation, index) => (
                            <tr key={quotation.id || index}>
                              {" "}
                              {/* Use a unique ID if available */}
                              <td>{index + 1}</td>
                              <td>{formatDate(quotation.date)}</td>
                              <td>{quotation.description || "NA"}</td>
                              <td>
                                {quotation.attachments &&
                                quotation.attachments.length > 0 ? (
                                  quotation.attachments.map((file) => (
                                    <div
                                      key={file.file_id}
                                      className="attachment-item"
                                    >
                                      {" "}
                                      {/* Use a unique ID for the file */}
                                      <span>
                                        {file.filename.length > 15
                                          ? `${file.filename.substring(
                                              0,
                                              15
                                            )}...`
                                          : file.filename}
                                        ({file.file_size} KB)
                                      </span>
                                      <a
                                        href={`${API_URL}/api/download_pr_attachment/${file.file_id}/${file.file_type}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download // Force download
                                      >
                                        <FiDownload className="download-icon" />
                                      </a>
                                    </div>
                                  ))
                                ) : (
                                  <span>No attachments available</span> // Keep this line if you want to indicate that there are no attachments
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No pr request history available
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PrVendorDetails;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";

function EditOutward({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [outwardData, setOutwardData] = useState({
    DepartmentName: "",
    DepartmentCode: "",
  });
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedmattCode, setSelectedMattCode] = useState(null);
  const [mattCodeOptions, setMattCodeOptions] = useState([]);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [poData, setPoData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setOutwardData((prevState) => ({
      ...prevState,
      date: date,
    }));
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_department/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data[0];
        const formattedData = {
          DepartmentName: responseData.DepartmentName,
          DepartmentCode: responseData.DepartmentCode,
        };
        setOutwardData(formattedData);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchDepartmentData();
  }, [ID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOutwardData({ ...outwardData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/po-configuration");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(outwardData).forEach((key) => {
      if (!outwardData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    });

    if (error === false) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.put(
          `${API_URL}/api/update_department/${ID}`,
          outwardData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/po-configuration");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    }
  };
  const handleBackProject = () => {
    navigate("/po-configuration");
  };
  const fetchVendorData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      // Map vendor data to { label, value } format for the Select component
      const formattedVendors = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name, // Display the vendor name
        value: vendor.id, // Use vendor ID as the value
      }));

      setVendorOptions(formattedVendors); // Set the mapped options
      // setTotalItems(response.data.TotalItems); // If needed for pagination
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
    fetchMattCodeData();
  }, []);
  const fetchMattCodeData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_details`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      // Map the fetched data to label-value pairs
      const formattedMattCodes = response.data.mat_code_details.map((mat) => ({
        label: mat.mat_code, // Display mat_code and description
        value: mat.mat_code_id, // Use mat_code_id as the value
      }));

      setMattCodeOptions(formattedMattCodes); // Set the mapped options
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Outward
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
              <div className="form-group-select">
                  <label htmlFor="mat_code">
                    Select Matt Code<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="matt_code"
                    options={mattCodeOptions}
                    placeholder="Select matt code"
                    isSearchable
                    // isMulti
                    onChange={setSelectedMattCode}
                    value={selectedmattCode}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    MIN (Material Issue Note) No
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="DepartmentCode"
                      value={outwardData.DepartmentCode}
                      onChange={handleInputChange}
                      placeholder="Enter MIN no"
                    />
                   
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="date">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      name="DepartmentCode"
                      value={outwardData.DepartmentCode}
                      onChange={handleInputChange}
                      placeholder="Enter department code"
                    />
                  
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditOutward;

import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select, { components } from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { CiCirclePlus } from "react-icons/ci";
import AddEquipmentType from "../Popups/AddEquipmentType";

const { Control, Menu } = components;
function EuipmentSubTypeForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [equipmentSubTypeData, setEquipmentSubTypeData] = useState({
    EquipmentType: "",
    EquipmentSubType: "",
    EquipmentTypeId: "",
  });
  const [showequipmentTypePopup, setShowequipmentTypePopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentSubTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(equipmentSubTypeData);
  };

  const handleCancelClick = () => {
    navigate("/equipment-sub-type");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleSaveClick = async () => {
    const updatedEquipmentSubTypeData = {
      ...equipmentSubTypeData,
    };
    
    let error = false;
    
      if (!updatedEquipmentSubTypeData.EquipmentType || !updatedEquipmentSubTypeData.EquipmentSubType) {
        
        setSeverity("warning");
        showSnackbar("Required fields are empty");
        error = true;
      }
    

    if (error === false) {
      

      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");

        const response = await axios.post(
          `${API_URL}/api/add_equipment_sub_type`,
          updatedEquipmentSubTypeData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Equipment Sub Type Data addded successfully");
          setTimeout(() => {
            navigate("/equipment-sub-type");
          }, 1000);
        }
      } catch (error) {
        console.error("Error saving equipment sub type data:", error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleEquipmentTypeMenuOpen = () => {
    setEquipmentTypeMenuIsOpen(true);
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddEquipmentType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Type
      </button>
    </Menu>
  );
  const handleAddEquipmentType = () => {
    setShowequipmentTypePopup(true);
    setEquipmentTypeMenuIsOpen(false);
  };

  const closeEquipmentTypePopup = (newEquipment) => {
    setShowequipmentTypePopup(false);
    if (newEquipment) {
      setEquipmentTypes((prevDepartments) => [
        ...prevDepartments,
        newEquipment,
      ]);
      setSelectEdequipmentType(newEquipment);
      setEquipmentSubTypeData((prevFormData) => ({
        ...prevFormData,
        EquipmentType: newEquipment.label,
        EquipmentTypeId: newEquipment.value,
      }));
    }
  };

  const handleNewEquipmentType = (newEquipment) => {
    setEquipmentTypes((prevEquipmentTypes) => [
      ...prevEquipmentTypes,
      newEquipment,
    ]);

    const updatedEquipmentType = {
      label: newEquipment.label,
      value: newEquipment.value,
    };
    setSelectEdequipmentType(updatedEquipmentType);
    setEquipmentSubTypeData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: newEquipment.label,
      EquipmentTypeId: newEquipment.value,
    }));
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setEquipmentSubTypeData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
  };

  const fetchEquipmentType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the format expected by react-select
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Type:", error);
    }
  };

  useEffect(() => {
    fetchEquipmentType();
  }, []);

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipment Sub Type
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="newperson-user-form">
                <div className="form-group-select">
                  <label htmlFor="EquipmentType">
                    Equipment Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentType"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="EquipmentSubType">
                    Equipment Sub Type<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="EquipmentSubType"
                      value={equipmentSubTypeData.EquipmentSubType}
                      onChange={handleInputChange}
                      placeholder="Enter Sub Type"
                    />
                  </div>
                </div>
              </div>
            </form>
            {showequipmentTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentType
                  closePopup={closeEquipmentTypePopup}
                  setSelectEdequipmentType={setSelectEdequipmentType}
                  handleNewEquipmentType={handleNewEquipmentType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EuipmentSubTypeForm;

import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function GeneratePO({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { id } = useParams();
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poNumber, setPoNumber] = useState(""); // Add state for PO number
  const [poData, setPoData] = useState([]);
  const initialRow = {
    mat_code: "",
    quantity: "",
    price: "",
    description: "",
  };

  const handlePoNumberChange = (e) => {
    setPoNumber(e.target.value); // Update the poNumber state inside this function
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...poData];
    updatedData[index][field] = value;
    setPoData(updatedData);
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setPoData((prevData) => ({ ...prevData, [name]: value }));
  };

  // useEffect(() => {
  //   const fetchPOData = async () => {
  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.get(
  //         `${API_URL}/api/get_po_pr_list_items/${id}`,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );

  //       const responseData = response.data;
  //       console.log("Response data:", responseData);

  //       // Set vendor if present in the response
  //       if (responseData.vendor_name) {
  //         const selectedVendorOption = vendorOptions.find(
  //           (vendor) => vendor.vendor_name === responseData.vendor_name
  //         );
  //         setSelectedVendor(selectedVendorOption || null);
  //       }

  //       // Set PO data if available
  //       if (
  //         responseData.po_list_items &&
  //         Array.isArray(responseData.po_list_items)
  //       ) {
  //         setPoData(responseData.po_list_items);
  //       } else {
  //         setPoData([]); // Fallback to empty array
  //       }
  //     } catch (error) {
  //       console.error("Error fetching PO data:", error);
  //     }
  //   };

  //   fetchPOData();
  // }, [id, vendorOptions]);

  useEffect(() => {
    const fetchPOData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_po_pr_list_items/${id}`,
          {
            headers: {
              Authorization: authKey,
            }
          }
        );
  
        const responseData = response.data;
        console.log("Response data:", responseData);
  
        // Set vendor if present in the response
        if (responseData.vendor_name && vendorOptions.length > 0) {
          const selectedVendorOption = vendorOptions.find(
            (vendor) => vendor.label === responseData.vendor_name
          );
          setSelectedVendor(selectedVendorOption || null);
        }
  
        // Set PO data if available
        if (
          responseData.po_list_items &&
          Array.isArray(responseData.po_list_items)
        ) {
          setPoData(responseData.po_list_items);
        } else {
          setPoData([]); // Fallback to empty array
        }
      } catch (error) {
        console.error("Error fetching PO data:", error);
      }
    };

    fetchPOData();
  }, [id, vendorOptions]);

  // const generatePoNumber = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.get(`${API_URL}/api/generate_po_number`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     });

  //     if (response.data && response.data.po_number) {
  //       return response.data.po_number;
  //     } else {
  //       throw new Error("PO number not found in response");
  //     }
  //   } catch (error) {
  //     console.error("Error generating PO number:", error);
  //     return ""; // Return an empty string if there's an error
  //   }
  // };

  // useEffect(() => {
  //   const fetchPoNumber = async () => {
  //     const po_number = await generatePoNumber();
  //     setPoData((prevData) => {
  //       if (prevData.length > 0) {
  //         const updatedRows = [...prevData];
  //         updatedRows[0].po_no = po_number; // Update the first row's PO number
  //         return updatedRows;
  //       }
  //       return prevData;
  //     });
  //   };
  //   fetchPoNumber();
  // }, []);

  // const generatePoNumber = async () => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const response = await axios.get(`${API_URL}/api/generate_po_number`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //     });

  //     if (response.data && response.data.po_number) {
  //       return response.data.po_number;
  //     } else {
  //       throw new Error("PO number not found in response");
  //     }
  //   } catch (error) {
  //     console.error("Error generating PO number:", error);
  //     return ""; // Return an empty string if there's an error
  //   }
  // };

  // useEffect(() => {
  //   const fetchPoNumber = async () => {
  //     const po_number = await generatePoNumber();
  //     setPoNumber(po_number); // Set the generated PO number in state
  //     setPoData((prevData) => {
  //       if (prevData.length > 0) {
  //         const updatedRows = [...prevData];
  //         updatedRows[0].po_no = po_number; // Update the first row's PO number
  //         return updatedRows;
  //       }
  //       return prevData;
  //     });
  //   };
  //   fetchPoNumber();
  // }, []);

  const handleSaveClick = async () => {
    console.log("poData:", poData);
    console.log("Selected Vendor:", selectedVendor);

    if (!selectedVendor || !selectedVendor.value) {
      setSeverity("error");
      showSnackbar("Please select a vendor");
      return;
    }

    console.log("Selected Vendor ID:", selectedVendor.value);
    console.log("PO Number:", poNumber); // Accessing the PO number from state

    const payload = {
      pr_request_id: id,
      po_no: poNumber, // Use the state value for PO number
      vendor_id: selectedVendor.value,
      items: poData.map((row) => ({
        description: row.description || "",
        mat_code_id: row.mat_code_id || "",
        price: row.price || 0,
        est_unit_rate: row.est_unit_rate || 0,
        quantity: row.quantity || 0,
      })),
    };

    console.log("Payload to be sent:", JSON.stringify(payload, null, 2));

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.post(
        `${API_URL}/api/add_po_list_bulk`,
        payload,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setSeverity("success");
        showSnackbar("PO configurations added successfully");
        navigate("/po-list");
      }
    } catch (error) {
      console.error(
        "Error saving PO configurations:",
        error.response ? error.response.data : error.message
      );
      setSeverity("error");
      showSnackbar("An error occurred while saving PO configurations");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = () => {
    navigate("/approved-pr-requests");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchVendorData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
      });

      const formattedVendors = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendorOptions(formattedVendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendorData();
  }, []);

  // const handleSaveClick = async () => {
  //   console.log("poData:", poData);
  //   console.log("Selected Vendor:", selectedVendor);

  //   if (!selectedVendor || !selectedVendor.value) {
  //     setSeverity("error");
  //     showSnackbar("Please select a vendor");
  //     return;
  //   }

  //   console.log("Selected Vendor ID:", selectedVendor.value);
  //   console.log("PO Number:", poData[0].po_no); // Accessing po_no from the first row

  //   const payload = {
  //     pr_request_id: id,
  //     po_no: poData[0].po_no, // Ensure po_no is coming from the first row
  //     vendor_id: selectedVendor.value,
  //     items: poData.map((row) => ({
  //       description: row.description || "",
  //       mat_code_id: row.mat_code_id || "", // Ensure this field is correctly set
  //       price: row.price || 0,
  //       est_unit_rate: row.est_unit_rate || 0,
  //       quantity: row.quantity || 0, // Ensure quantity is included
  //     })),
  //   };

  //   console.log("Payload to be sent:", JSON.stringify(payload, null, 2));

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/add_po_list_bulk`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       console.log(response.data.message);
  //       setSeverity("success");
  //       showSnackbar("PO configurations added successfully");
  //       navigate("/po-list");
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error saving PO configurations:",
  //       error.response ? error.response.data : error.message
  //     );
  //     setSeverity("error");
  //     showSnackbar("An error occurred while saving PO configurations");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New PO Generate
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="form-group">
                <label htmlFor="po_no">
                  PO Number<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="po_no"
                    value={poNumber} // Bind the value to poNumber state
                    onChange={handlePoNumberChange} // Update poNumber state on input change
                    placeholder="Enter po number"
                  />
                </div>
              </div>
              <div className="form-group-select">
                <label htmlFor="DepartmentName">
                  Select Vendor<span className="text-danger">*</span>
                </label>
                <Select
                  id="EquipmentSubType"
                  options={vendorOptions}
                  placeholder="Select vendor"
                  isSearchable
                  value={selectedVendor} // Set the current value of selectedVendor
                  onChange={(selectedOption) =>
                    setSelectedVendor(selectedOption)
                  } // Update the selected vendor
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="attachment">Attachment<span className="text-danger">*</span></label>
                <input/>
               
              </div> */}
            </div>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Item Table
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <table className="action-table action-multilevel-data">
                    <thead className="action-purchase-heads">
                      <tr>
                        <th className="th2">MAT CODE</th>
                        <th className="th3">QUANTITY</th>
                        <th className="th4">ESTIMATED UNIT RATE</th>
                        <th className="th5">DESCRIPTION</th>
                        <th className="th4">PRICE</th>
                      </tr>
                    </thead>
                    <tbody className="action-purchase-scroll-bodys">
                      {poData.length > 0 ? (
                        poData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <span>{row.mat_code || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.quantity || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.est_unit_rate || "N/A"}</span>
                            </td>
                            <td>
                              <span>{row.description || "N/A"}</span>
                            </td>
                            <td className="th3">
                              <input
                                type="number"
                                placeholder="Enter Price"
                                value={row.price}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default GeneratePO;

import React, { useState, useEffect, useRef } from "react";
// import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function InwardForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedmattCode, setSelectedMattCode] = useState(null);
  const [mattCodeOptions, setMattCodeOptions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [poOptions, setPoOptions] = useState([]);
  const [poDetails, setPoDetails] = useState(null);
  const [inwardData, setInwardData] = useState({
    invoice_no: "",
    invoice_attachment: null,
    inwardQuantities: {}, // Store inward quantities by item ID
  });

  const handleUploadButtonClick = (fileInputRef) => {
    fileInputRef.current.click();
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInwardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate("/po-inbound-list");
  };


  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
  
  //   // Validate data
  //   Object.keys(inwardData).forEach((key) => {
  //     if (!inwardData[key]) {
  //       errors[key] = `${key} is required`;
  //       setSeverity("warning");
  //       error = true;
  //     }
  //   });
  
  //   // Prepare items data with inbounded_qty and description
  //   const items = poDetails?.po_list_items.map((item) => ({
  //     po_id: selectedPO.value,
  //     invoice_no: inwardData.invoice_no,
  //     mat_code_id: item.mat_code,
  //     price: item.price,
  //     qty: item.quantity,
  //     // Default inbounded_qty to item.quantity if not specified
  //     inbounded_qty: inwardData.inwardQuantities[item.id] || item.quantity,
  //     description: item.description || "", // Fetch description from the item or keep it as an empty string
  //   })) || [];
  
  //   if (items.length === 0) {
  //     showSnackbar("No items to save");
  //     return; // Prevent further execution if there are no items
  //   }
  
  //   // Validate if files are selected
  //   if (selectedFiles.length === 0) {
  //     showSnackbar("Please select files to upload");
  //     return;
  //   }
  
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     // Prepare FormData
  //     const formData = new FormData();
  //     formData.append("items", JSON.stringify(items));
      
  //     // Append file type as a plain string
  //     formData.append("file_types", "inbound_po"); // Change this line
  
  //     // Add selected files to FormData
  //     selectedFiles.forEach((file) => {
  //       if (file) {
  //         formData.append("attachments", file);
  //       }
  //     });
  
  //     // Log FormData for debugging
  //     console.log("FormData entries:");
  //     for (let pair of formData.entries()) {
  //       console.log(pair[0] + ', ' + pair[1]);
  //     }
  
  //     // Send the POST request
  //     const response = await axios.post(
  //       `${API_URL}/api/inbound_purchase_orders`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  
  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Purchase orders inbound successfully");
  //       setTimeout(() => {
  //         navigate("/po-inbound-list");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving inbound purchase orders:", error);
  //     console.log("Axios error response:", error.response ? error.response.data : "No response from server");
  //     setSeverity("error");
  //     showSnackbar("Failed to save inbound purchase orders");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
  
    // Validate data
    Object.keys(inwardData).forEach((key) => {
      if (!inwardData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });
  
    // Extract po_id and invoice_no outside the loop
    const po_id = selectedPO.value;
    const invoice_no = inwardData.invoice_no;
    const vendor_id = selectedPO?.vendor_id;

  
    // Prepare items data without po_id and invoice_no
    const items = poDetails?.po_list_items.map((item) => ({
      mat_code_id: item.mat_code_id,
      price: item.price,
      qty: item.quantity,
      est_unit_rate: item.est_unit_rate,
      // Default inbounded_qty to item.quantity if not specified
      inbounded_qty: inwardData.inwardQuantities[item.id] || item.quantity,
      description: item.description || "", // Fetch description from the item or keep it as an empty string
    })) || [];
  
    if (items.length === 0) {
      showSnackbar("No items to save");
      return; // Prevent further execution if there are no items
    }
  
    // Validate if files are selected
    if (selectedFiles.length === 0) {
      showSnackbar("Please select files to upload");
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Prepare FormData
      const formData = new FormData();
      formData.append("po_id", po_id); // Add po_id outside the items array
      formData.append("vendor_id", vendor_id); // Include vendor_id
      formData.append("invoice_no", invoice_no); // Add invoice_no outside the items array
      formData.append("items", JSON.stringify(items)); // Add items array without po_id and invoice_no
  
      // Append file type as a plain string
      formData.append("file_types", "inbound_po");
  
      // Add selected files to FormData
      selectedFiles.forEach((file) => {
        if (file) {
          formData.append("attachments", file);
        }
      });
  
      // Log FormData for debugging
      console.log("FormData entries:");
      for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
  
      // Send the POST request
      const response = await axios.post(
        `${API_URL}/api/inbound_purchase_orders`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Purchase orders inbound successfully");
        setTimeout(() => {
          navigate("/po-inbound-list");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving inbound purchase orders:", error);
      console.log("Axios error response:", error.response ? error.response.data : "No response from server");
      setSeverity("error");
      showSnackbar("Failed to save inbound purchase orders");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_all_po_list`, {
        headers: { Authorization: authKey },
      });

      // Check if the response has the expected data structure
      const options = response.data.POLists.map((item) => ({
        value: item.id,  // Accessing po_list.id
        label: item.po_no,  // Accessing po_list.po_no
        vendor_id: item.vendor_id,
      }));

      setPoOptions(options);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPO) {
      const fetchPoDetails = async () => {
        try {
          const authKey = localStorage.getItem("authKey");
          const response = await axios.get(
            `${API_URL}/api/get_po_list_items/${selectedPO.value}`,
            {
              headers: { Authorization: authKey },
            }
          );

          setPoDetails(response.data);
        } catch (error) {
          console.error("Error fetching PO details:", error.message);
        }
      };

      fetchPoDetails();
    }
  }, [selectedPO]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handlePOChange = (selectedOption) => {
    setSelectedPO(selectedOption);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleInwardQuantityChange = (itemId, value) => {
    // If the input is cleared, you can either set it to an empty string or remove the entry
    setInwardData((prevState) => ({
      ...prevState,
      inwardQuantities: {
        ...prevState.inwardQuantities,
        [itemId]: value === "" ? null : value, // Set to null or empty string based on your preference
      },
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setInwardData((prevState) => ({
      ...prevState,
      [name]: files[0], // Assuming only one file can be selected
    }));
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Inward
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <div className="action-purchase-header">
              <div className="form-group-select">
                <label htmlFor="po_no">PO Number</label>

                <Select
                  id="POSelect"
                  options={poOptions}
                  placeholder="Select PO Number"
                  isSearchable
                  onChange={handlePOChange}
                  value={selectedPO}
                />
              </div>
              <div className="form-group">
                <label htmlFor="invoice_no">
                  Invoice No
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="invoice_no"
                    value={inwardData.invoice_no}
                    onChange={handleInputChange}
                    placeholder="Enter invoice no"
                  />
                </div>
              </div>

              <div className="form-group-attachments">
                <div className="form-group">
                  <label htmlFor="gst_attachments">
                    Attachment
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={() => handleUploadButtonClick(fileInputRef)}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    type="file"
                    id="gst_attachments"
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileInputChange(
                        e.target.files,
                        setSelectedFiles
                      )
                    }
                    style={{ display: "none" }}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc5">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                  // onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Inward Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side">
                  {/* <Link to="/purchaseform" className='purchase-new'>
              <button className='new-button'><HiOutlinePlus className="new-plus-icon" /> New</button>
              </Link> */}
                </div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-table">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="th2">VENDOR</th>
                          <th className="th2">MAT CODE</th>
                          <th className="th3">QUANTITY</th>
                          <th className="th4">ESTIMATED UNITE RATE</th>
                        
                          <th className="th4">DESCRIPTION</th>
                          <th className="th4">PRICE</th>
                          <th className="th4">INWARD QUANTITY</th>
                          {/* <th className="th">ACTION</th> */}
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys">
                        {poDetails &&
                          poDetails.po_list_items.map((item) => (
                            <tr key={item.id}>
                              <td>{poDetails.po_list.vendor_name}</td>
                              <td>{item.mat_code}</td>
                              <td>{item.quantity}</td>
                              <td>{item.est_unit_rate}</td>
  
                              
                              <td>{item.description}</td>
                              <td>{item.price}</td>

                              {/* Input for inward quantity with default value */}
                              <td className="th3">
                                <input
                                  type="number"
                                  value={
                                    inwardData.inwardQuantities[item.id] !== undefined
                                      ? inwardData.inwardQuantities[item.id]
                                      : item.quantity
                                  }
                                  onChange={(e) =>
                                    handleInwardQuantityChange(item.id, e.target.value)
                                  }
                                  placeholder="Enter inward quantity"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>

                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows"></div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="button-group">
                <button
                  className="data-form-save-button"
                  type="save"
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                <button
                  className="data-form-cancel-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default InwardForm;

import React, { useState, useEffect, useRef } from "react";
import "./ReportPage.css";
import Select from "react-select";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { Button, TextField } from "@mui/material";
import { MdArrowForwardIos } from "react-icons/md";
import DatePicker from "react-datepicker";
import ExportPopup from "../Popups/ExportPopup";

function SchedulesReport({ isSidebarExpanded }) {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportStartDate, setReportStartDate] = useState(null);
  const [reportEndDate, setReportEndDate] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  const handleExportButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExportPopupOpen(true);
  };

  const handleExport = async (format, filter, startDate, endDate) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const params = new URLSearchParams({
        format,
        filter_option: filter.value,
        ...(filter.value === "custom_date" && {
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
        }),
      }).toString();

      const response = await axios.get(
        `${API_URL}/api/export_procurement_report?${params}`,
        {
          headers: {
            Authorization: authKey,
          },
          responseType: "blob", // To handle binary file data
        }
      );

      if (response.status === 200) {
        // Handle file download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `procurement_report.${format}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("Error exporting schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error exporting schedule:", error.message);
    }
  };

  const fetchData = async (params) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/pr_report`, {
        headers: {
          Authorization: authKey,
        },
        params: params,
      });

      setReports(response.data.report);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching schedule report:", error.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Next Month", value: "next_month" },
    { label: "Current Week", value: "current_week" },
    { label: "Previous Month", value: "previous_month" },
    { label: "Previous Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];
  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions.find((option) => option.value === "current_month")
  );
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(date)
      .toLocaleDateString("en-CA", options)
      .replace(/-/g, "/");
  };

  // const handleFilterScheduler = async (
  //   filter_option,
  //   start_date = null,
  //   end_date = null
  // ) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     let params = {};

  //     if (filter_option === "custom_date" && start_date && end_date) {
  //       params.start_date = formatDate(start_date);
  //       params.end_date = formatDate(end_date);
  //     } else {
  //       params.filter_option = filter_option;
  //     }

  //     const response = await axios.get(`${API_URL}/api/pr_report`, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "application/json",
  //       },
  //       params: params,
  //     });
  //     fetchData(params);
  //     if (response.status === 200) {
  //       console.log("Filtered response:", response.data); // Log the response
  //       if (response.data && response.data.length > 0) {
  //         setReports(response.data);
  //       } else {
  //         console.error("No data found for the selected filter option.");
  //       }
  //     } else {
  //       console.error("Error fetching scheduler data:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching scheduler data:", error.message);
  //   }
  // };

  const handleFilterScheduler = async (
    filter_option,
    start_date = null,
    end_date = null
  ) => {
    try {
      const authKey = localStorage.getItem("authKey");

      let params = {};

      if (filter_option === "custom_date" && start_date && end_date) {
        params.start_date = formatDate(start_date);
        params.end_date = formatDate(end_date);
      } else {
        params.filter_option = filter_option;
      }

      const response = await axios.get(`${API_URL}/api/pr_report`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: params,
      });
      fetchData(params);
      if (response.status === 200) {
        console.log("Filtered response:", response.data); // Log the response
        if (response.data) {
          // Check the structure of date_range
          const dateRange = response.data.date_range;
          if (dateRange.start_date && dateRange.end_date) {
            setReportStartDate(dateRange.start_date);
            setReportEndDate(dateRange.end_date);
          } else if (dateRange.date) {
            setReportStartDate(dateRange.date);
            setReportEndDate(null); // Clear end date if not available
          }

          // Assuming report data is stored here
          setReports(response.data.report);
        } else {
          console.error("No data found for the selected filter option.");
        }
      } else {
        console.error("Error fetching scheduler data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
    }
  };

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  useEffect(() => {
    if (selectedFilter && selectedFilter.value !== "custom_date") {
      handleFilterScheduler(selectedFilter.value);
    } else if (
      selectedFilter &&
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      handleFilterScheduler(
        selectedFilter.value,
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedFilter, selectedStartDate, selectedEndDate]);

  const containerRef = useRef(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? reports.map((schedule) => schedule.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === reports.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleArchiveschedule = async () => {};

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleRefresh = () => {
    if (selectedFilter && selectedFilter.value !== "custom_date") {
      handleFilterScheduler(selectedFilter.value);
    } else if (
      selectedFilter &&
      selectedFilter.value === "custom_date" &&
      selectedStartDate &&
      selectedEndDate
    ) {
      handleFilterScheduler(
        selectedFilter.value,
        selectedStartDate,
        selectedEndDate
      );
    }
  };

  // const handleViewClick = async (department_id, status) => {
  //   navigate(`/schedule-report-view/${department_id}/${status}`);
  //   console.log("status:", status);
  // };

  const handleViewClick = async (department_id, pr_status) => {
    navigate(
      `/procurement-report-view/${department_id}/${selectedFilter.value}/${pr_status}`
    );
  };
  const formatDates = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      return ""; // Return an empty string if the date is invalid
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string for invalid dates
    }

    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  return (
    <>
      {!isMainContainerVisible && (
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="print">
                      <IoPrintOutline className="print-icon" />
                    </button>
                    <button className="bulk-update">Bulk Update</button>
                    <button
                      className="delete"
                      onClick={handleDeleteButtonClick}
                    >
                      <HiOutlineDotsHorizontal className="delete-icon" />
                    </button>
                    {isDeletePopupOpen && (
                      <div className="delete-popup">
                        <div className="delete-popup-content">
                          <button onClick={handleDeletePopup}>
                            Move to archive
                          </button>
                        </div>
                      </div>
                    )}
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleArchiveschedule}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                {/* <div className="data-list-heading-content-left-side">
                  Procurement Reports
                </div> */}
                <div className="data-list-heading-content-left-side">
                  Procurement Reports
                  {/* {selectedFilter.value === "all" &&
                  reportStartDate === "All dates" ? (
                    <span> (Date: All dates)</span>
                  ) : selectedFilter.value !== "all" &&
                    reportStartDate &&
                    reportEndDate ? (
                    <span>
                      {" "}
                      (Date Range: {formatDates(reportStartDate)} -{" "}
                      {formatDates(reportEndDate)})
                    </span>
                  ) : selectedFilter.value !== "all" && reportStartDate ? (
                    <span> (Date: {formatDates(reportStartDate)})</span>
                  ) : null} */}
                  {selectedFilter.value === "all" &&
                  reportStartDate === "All dates" ? (
                    <span>
                      <span style={{ color: "#007bff" }}>
                        {" "}
                        (Date: All dates)
                      </span>
                    </span>
                  ) : selectedFilter.value !== "all" &&
                    reportStartDate &&
                    reportEndDate ? (
                    <span>
                      <span style={{ color: "#007bff" }}>
                        {" "}
                        (Date Range: {formatDates(reportStartDate)} -{" "}
                        {formatDates(reportEndDate)}){" "}
                      </span>
                    </span>
                  ) : selectedFilter.value !== "all" && reportStartDate ? (
                    <span>
                      <span style={{ color: "#007bff" }}>
                        {" "}
                        (Date: {formatDates(reportStartDate)}){" "}
                      </span>
                    </span>
                  ) : null}
                </div>

                <div className="data-list-heading-content-right-side">
                  {/* <div className="dropdown-container">
                      <Select
                        options={statusOptions}
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        placeholder="Select Status"
                        className="project-dropdown"
                      />
                    </div> */}
                  <div className="dropdown-container">
                    <Select
                      value={selectedFilter}
                      onChange={handleFilterChange}
                      options={filterOptions}
                      className="project-dropdown"
                    />
                  </div>
                  {selectedFilter.value === "custom_date" && (
                    <div className="input-group-datepickers">
                      <DatePicker
                        placeholderText="Select Date Range"
                        className="dates"
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        selectsRange={true}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        onChange={(dates) => {
                          const [start, end] = dates;
                          setSelectedStartDate(start);
                          setSelectedEndDate(end);
                        }}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField {...startProps} variant="standard" />
                            <TextField {...endProps} variant="standard" />
                          </>
                        )}
                        startText="Start Date"
                        endText="End Date"
                      />
                    </div>
                  )}

                  <button
                    className="export-btn"
                    onClick={handleExportButtonClick}
                  >
                    Export
                  </button>
                  <ExportPopup
                    isOpen={isExportPopupOpen}
                    onClose={() => {
                      setIsExportPopupOpen(false);
                      setAnchorEl(null);
                    }}
                    onExport={handleExport}
                    selectedFilter={selectedFilter}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    anchorEl={anchorEl}
                  />

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button className="sort-button" onClick={handleSortpopup}>
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container">
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                        </div>
                        <div className="sort-by-button"></div>
                        {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-bodys">
              <div className="data-list-table-report">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : reports.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        <th>DEPARTMENT</th>
                        <th className="approve-status">APPROVE PENDING</th>
                        <th className="approved-status">APPROVED</th>
                        <th className="rejected-status">REJECTED</th>
                        <th className="returned-status">RETURNED</th>
                        <th className="total-statuss">TOTAL PR REQUESTS</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {reports.map((report, index) => (
                        <tr key={index} className="hoverable-row">
                          <td>{index + 1}</td>
                          <td>{report.department_name || "NA"}</td>
                          <td
                            className={`approve-status ${
                              report.pending === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.pending &&
                              report.pending > 0 &&
                              handleViewClick(report.department_id, "pending")
                            }
                          >
                            {report.pending || "0"}
                            {report.pending !== null &&
                              report.pending !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          <td
                            className={`approved-status ${
                              report.approved === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.approved &&
                              report.approved > 0 &&
                              handleViewClick(report.department_id, "approved")
                            }
                          >
                            {report.approved || "0"}
                            {report.approved !== null &&
                              report.approved !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>
                          <td
                            className={`rejected-status ${
                              report.rejected === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.rejected &&
                              report.rejected > 0 &&
                              handleViewClick(report.department_id, "rejected")
                            }
                          >
                            {report.rejected || "0"}
                            {report.rejected !== null &&
                              report.rejected !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>

                          <td
                            className={`returned-status ${
                              report.returned === "0" ? "non-clickable" : ""
                            }`}
                            onClick={() =>
                              report.returned &&
                              report.returned > 0 &&
                              handleViewClick(report.department_id, "returned")
                            }
                          >
                            {report.returned || "0"}
                            {report.returned !== null &&
                              report.returned !== "0" && (
                                <MdArrowForwardIos className="total-report-icon" />
                              )}
                          </td>

                          <td
                            className="total-status"
                            onClick={() =>
                              handleViewClick(
                                report.department_id,
                                "total_schedules"
                              )
                            }
                          >
                            {report.total_pr || "0"}
                          </td>

                          {/* <td>
                              <button
                                onClick={() => handleViewClick(report.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Schedules Report Found
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default SchedulesReport;

import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import AmcView from "../Views/AmcView";

function AmcSchedules({ isSidebarExpanded, amcID }) {
  const [amcs, setAmcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [severity, setSeverity] = useState("success");
  const [popupUserID, setPopupUserID] = useState(null);
  const fetchTimeout = useRef(null);
  const navigate = useNavigate();

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  const containerRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_amcs`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setAmcs(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching amc schedules data:", error.message);
      setLoading(false);
    }
  };


  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage); 
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_amc`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setAmcs(response.data.Items);
        setTotalItems(response.data.TotalItems)
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
  } finally {
    setLoading(false);
    setIsSearching(false);
  }
  };

  //   useEffect(() => {
  //     const delay = setTimeout(() => {
  //       handleSearch();
  //     }, 600);
  //     return () => clearTimeout(delay);
  //   }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage); 
      }
    }, 600); 

    return () => clearTimeout(fetchTimeout.current); 
  }, [searchInput, page, itemsPerPage]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? amcs.map((amc) => amc.ID) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (amcID) => {
    const updatedSelectedRows = selectedRows.includes(amcID)
      ? selectedRows.filter((id) => id !== amcID)
      : [...selectedRows, amcID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === amcs.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = amcs.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setAmcs(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const handleRefresh = () => {
    // fetchData();
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  // const handleGenerateScheduler = async (amcID) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/generate_amc_scheduler/${amcID}`,
  //       null,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       showSnackbar("Scheduler generated successfully");
  //       fetchData(); // Refresh the data after generating scheduler
  //     } else {
  //       console.error("Error generating scheduler:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error generating scheduler:", error.message);
  //   }
  // };


  const handleGenerateScheduler = async (amcID) => {
    try {
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.post(
        `${API_URL}/api/generate_amc_scheduler/${amcID}`,
        null,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        // Scheduler generated successfully
        showSnackbar("Scheduler generated successfully", "success");
      } else {
        const message = response.data.message || "An unexpected error occurred";
        if (message === "AMC schedule is already generated") {
          showSnackbar(message, "error"); 
        } else {
          showSnackbar(message, "error");
        }
      }
    } catch (error) {
      // Handle error response from API
      const message = error.response?.data?.message || "An error occurred while generating the scheduler";
      showSnackbar(message, "error"); 
    }
  };
  
  const togglePopup = (amcID) => {
    setPopupUserID(amcID);
    setIsMainContainerVisible(true);
  };

  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); // Close the user view
  };
  
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All AMCs
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/amc-schedules-form" className="data-list-new">
                      <Tooltip title="New Amc Schedule" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          {/* <button
                            className="delete-item"
                            onClick={handleArchiveDeparment}
                          >
                            Archived Department
                          </button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : amcs.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>DEPARTMENT</th>
                          <th>NAME</th>
                          <th>SERVICE PROVIDER</th>
                          {/* <th>USER</th> */}
                          <th>STATUS</th>
                          <th>START DATE</th>
                          {/* <th>SCHEDULE START DATE</th> */}
                          <th>END DATE</th>
                          <th>PO NUMBER</th>
                          <th>PO DATE</th>
                          {/* <th>DAYS BEFORE</th>
                          <th>REMINDER FREQUENCY</th>
                          <th>NO. OF PREVENTIVE VISITS</th>
                          <th>NO. OF BREAKDOWN VISITS</th>
                          <th>SERVICE DESCRIPTION</th> */}
                          <th>ACTION</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {amcs.map((amc, index) => (
                          <tr key={amc.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(amc.id)}
                                onChange={() => handleSelectRow(amc.id)}
                              />
                            </td>

                            <td>{amc.serial_no}</td>
                            {/* <td>{amc.department_name || "NA"}</td> */}
                            <td className="view-data" onClick={() => togglePopup(amc.id)}>{amc.department_name || "NA"}</td>
                            <td>{amc.amc_name || "NA"}</td>
                            <td>{amc.service_provider_name || "NA"}</td>
                            {/* <td>{amc.user_name || "NA"}</td> */}
                            {/* <td>{amc.amc_status || "NA"}</td> */}
                            <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    amc.amc_status === "Active"
                                      ? "#2980B9"
                                      : amc.amc_status === "Expired"
                                        ? "#F81700"
                                       
                                         
                                                  : "transparent",
                                  width: "6vw",
                                }}
                              >
                               {amc.amc_status}
                              </div>
                              </td>
                            <td>{formatDate(amc.start_date)}</td>
                            {/* <td>{formatDate(amc.schedule_start_date)}</td> */}
                            <td>{formatDate(amc.end_date)}</td>
                            <td className="ponumber-th">{amc.po_no || "NA"}</td>
                            <td>{formatDate(amc.po_date)}</td>
                            {/* <td>{amc.days_before || "NA"}</td>
                            <td>{amc.reminder_frequency || "NA"}</td>
                            <td>{amc.preventive_no || "NA"}</td>
                            <td>{amc.breakdown_no || "NA"}</td>
                            <td>{amc.services || "NA"}</td> */}
                            <td>
                              <button
                                className="generate-scheduler-button"
                                onClick={() => handleGenerateScheduler(amc.id)}
                              >
                                Generate Schedule
                              </button>
                            </td>

                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-amc/${amc.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No AMC Schedules Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {isMainContainerVisible && (
          <div className="main-containers">
            <AmcView amcID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AmcSchedules;
